import React, { useState, useCallback, useEffect } from "react";
import ReactFlow, {
  MiniMap,
  Controls,
  Background,
  useEdgesState,
  useNodesState,
} from "react-flow-renderer";
import "react-flow-renderer/dist/style.css";
import CustomNode from "./CustomNode";
import {
  Box,
  Divider,
  Grid,
  Stack,
  Typography,
  IconButton,
  Avatar,
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import SubdirectoryArrowRightIcon from "@mui/icons-material/SubdirectoryArrowRight";
import axios from "axios";
import { useTheme } from "@emotion/react";
import { getAllEndUsers } from "../../../apiCalls/allApiCalls"; // API call to fetch all users
import { useSelector } from "react-redux"; // Redux hook to select state
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { tileShadows } from "../../../pages/Root";
import { height } from "@mui/system";
import Subordinates from "./Subordinates";

// Custom styles for scrollbar
const scrollBarStyles = {
  "&::-webkit-scrollbar": {
    width: "8px",
  },
  "&::-webkit-scrollbar-track": {
    backgroundColor: "#f1f1f1",
    borderRadius: "10px",
    pr: 2,
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#888",
    borderRadius: "10px",
  },
  "&::-webkit-scrollbar-thumb:hover": {
    backgroundColor: "#555",
  },
};

const nodeTypes = { custom: CustomNode, subordinates: Subordinates };
const initialNodes = [
  {
    id: "1",
    data: {
      label: "Up One Level",
      type: "static",
      currentUser: "",
      userObj: {},
    },
    position: { x: 25, y: 20 },
    type: "custom",
    // style: {width: 160, height: 35}
  },
  {
    id: "2",
    data: { label: "CTO", currentUser: "", userObj: {}, type: "dynamic" },
    position: { x: 150, y: 80 },
    type: "custom",
  },
  {
    id: "3",
    data: { heirarchy: {}, subordinates: [] },
    position: { x: 260, y: 185 },
    type: "subordinates",
  },
];

const initialEdges = [
  { id: "e1-2", source: "1", target: "2", type: "smoothstep" },
  { id: "e2-3", source: "2", target: "3", type: "smoothstep" },
];

const NewOrgChart = ({ initialUserID, setInitialUserID, showMoreActive }) => {
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
  const theme = useTheme(); // Access the theme for styling
  // Destructure user details, all users, and hierarchy from Redux store
  const { allUsers, hierarchy } = useSelector((state) => state.users);
  const [currentUser, setCurrentUser] = useState(""); // Track the current user being displayed

  // if (hierarchy) {
  //   console.log("first", hierarchy)
  // }
  const onConnect = useCallback(
    (params) => setEdges((eds) => [...eds, params]),
    [setEdges]
  );

  const [expandedSubordinates, setExpandedSubordinates] = useState({}); // Track which subordinates are expanded
  const [userNames, setUserNames] = useState({}); // State to store Uid-name mapping

  // Navigate to the parent (one level up)
  const handleOneLevelUp = (e) => {
    // e.stopPropagation();

    if (!hierarchy || !currentUser) {
      console.error("Hierarchy or currentUser is missing!");
      return;
    }

    console.log("Hierarchy:", hierarchy);
    console.log("Current User:", currentUser);

    const newUser = hierarchy[currentUser]?.reportsTo;

    if (newUser) {
      console.log("Navigating to parent:", newUser);
      setCurrentUser(newUser);
      setInitialUserID(newUser);
    } else {
      console.warn("No parent found for current user:", currentUser);
    }
  };

  // When a subordinate is clicked, update the current user
  const handleSubordinateClick = (subordinateId) => {
    setCurrentUser(subordinateId);
    setInitialUserID(subordinateId);
  };

  // Toggle the expansion of subordinates
  const toggleSubordinate = (subordinateId) => {
    setExpandedSubordinates((prevState) => ({
      ...prevState,
      [subordinateId]: !prevState[subordinateId], // Toggle the expanded state
    }));
  };

  // Helper function to find user by their ID
  const findUserById = (Uid) => hierarchy[Uid];

  // Component to recursively render subordinates
  const RenderSubordinates = ({ subordinates }) => {
    return (
      <Stack pl={4}>
        {subordinates.map((subordinate) => {
          const subordinateDetails = findUserById(subordinate); // Get details of the subordinate
          const hasSubordinates = subordinateDetails?.subordinates?.length > 0; // Check if the subordinate has their own subordinates
          const isExpanded = expandedSubordinates[subordinate]; // Check if this subordinate is expanded

          return (
            <Box key={subordinate}>
              <Stack direction="row" alignItems="center" mt={0.5}>
                {/* Display subordinate's name, or fallback to their ID */}
                <Typography
                  onClick={() => handleSubordinateClick(subordinate)} // On click, set this subordinate as the current user
                  sx={{
                    color: theme.palette.text.thin,
                    cursor: "pointer",
                    transition: "color 0.3s, transform 0.3s",
                    textDecoration: "underline",
                    ":hover": {
                      transform: "scale(1.05)",
                    },
                  }}
                  // mt={2}
                >
                  {allUsers[subordinate]?.FirstName || subordinate}{" "}
                </Typography>
                {/* Show toggle button if this subordinate has more subordinates */}
                {hasSubordinates && (
                  <IconButton
                    onClick={() => toggleSubordinate(subordinate)} // Toggle visibility of subordinates
                    sx={{
                      transition: "color 0.3s, transform 0.3s",
                      ":hover": {
                        bgcolor: "transparent",
                        color: theme.palette.primary.light,
                        transform: "scale(1.2)",
                      },
                    }}
                  >
                    {isExpanded ? (
                      <RemoveCircleOutlineIcon
                        sx={{
                          fontSize: "18px",
                          color: theme.palette.secondary.light,
                        }}
                      /> // Show collapse icon
                    ) : (
                      <AddCircleOutlineIcon
                        sx={{
                          fontSize: "18px",
                          color: theme.palette.secondary.light,
                        }}
                      /> // Show expand icon
                    )}
                  </IconButton>
                )}
              </Stack>
              {/* Render subordinates if expanded */}
              {isExpanded && hasSubordinates && (
                <RenderSubordinates
                  subordinates={subordinateDetails.subordinates}
                />
              )}
            </Box>
          );
        })}
      </Stack>
    );
  };

  useEffect(() => {
    // Update `currentUser` dynamically
    if (currentUser) {
      console.log("current user updated: ", currentUser);
      setNodes((prevNodes) =>
        prevNodes.map((node) => ({
          ...node,
          data: {
            ...node.data,
            currentUser,
          },
        }))
      );
    }
  }, [currentUser]);

  useEffect(() => {
    // console.log("first", hierarchy);
    if (hierarchy) {
      setNodes((prevNodes) =>
        prevNodes.map((node) => ({
          ...node,
          data: {
            ...node.data,
            hierarchy: node.data.hierarchy ?? hierarchy, // Assign hierarchy only if not already present
          },
        }))
      );
    }
  }, [hierarchy]);

  useEffect(() => {
    // Update `allUsers` dynamically
    if (allUsers && currentUser) {
      setNodes((prevNodes) =>
        prevNodes.map((node) => ({
          ...node,
          data: {
            ...node.data,
            userObj: allUsers[currentUser],
            allUsers,
          },
        }))
      );
    }
  }, [allUsers, currentUser]);

  // Update the current user when initialUserID changes
  useEffect(() => {
    setCurrentUser(initialUserID);
  }, [initialUserID]);

  useEffect(() => {
    if (hierarchy && currentUser) {
      setNodes((prevNodes) =>
        prevNodes.map((node) => ({
          ...node,
          data: {
            ...node.data,
            subordinates: hierarchy[currentUser].subordinates,
          },
        }))
      );
    }
  }, [hierarchy, currentUser]);

  // Fetch user names from API (currently commented out)
  useEffect(() => {
    setUserNames(allUsers); // Set all users' data in state
    console.log("setting back to initialUserID");
    setCurrentUser(initialUserID);
    // When you update state in React using the functional updater syntax
    // (e.g., setNodes((prev) => ...)), the function receives the current state
    // as an argument. nds is a placeholder variable for "nodes".
    setNodes((nds) =>
      nds.map((node) => ({
        ...node,
        data: {
          ...node.data,
          handleOneLevelUp,
        },
      }))
    );
  }, []);

  return (
    <Box sx={{ position: "relative", height: showMoreActive ? 620 : 360 }}>
      <Box
        bgcolor={theme.palette.secondary.light}
        sx={{
          width: "100%",
          height: 30,
          position: "absolute",
          // top: 50,
          // left: 50,
          zIndex: 1,
          borderTopLeftRadius: "50px",
          borderTopRightRadius: "50px",
        }}
      >
        .
      </Box>
      <Box
        width={"100%"}
        height={"100%"}
        borderRadius={"28px"}
        bgcolor={theme.palette.tiles.bg}
        sx={{
          overflowY: "auto",
          // ...scrollBarStyles,
          position: "absolute",
          top: 5,
          zIndex: 100,
          color: "text.primary",
          // p: 5,
          ...tileShadows,
        }} // Higher z-index to overlap the first box}} // Apply scrollbar styles
      >
        {hierarchy && allUsers && currentUser && (
          <ReactFlow
            nodes={nodes}
            edges={edges}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            // onConnect={onConnect}
            // fitView
            nodeTypes={nodeTypes}
            panOnDrag={false} // dragging the entire canvas
            zoomOnScroll={false} // zooming with scroll
            zoomOnPinch={false} // zooming with pinch
            zoom={1} // Set the initial zoom level (100%)
            style={{
              height: "400px", // Larger height for testing overflow
              width: "100%",
            }}
            onNodeClick={(event, node) => {
              console.log("React Flow Node Clicked:", event.target.dataset.id);
              if (node.id !== "3") {
                handleOneLevelUp(event);
              } else {
                handleSubordinateClick(event.target.id);
              }
            }}
          />
        )}
      </Box>
    </Box>
  );
};
export default NewOrgChart;

import React, { useEffect, useState } from "react";
import {
  AppBar,
  Box,
  Toolbar,
  Typography,
  InputBase,
  Container,
  Stack,
  Autocomplete,
  InputAdornment,
} from "@mui/material";
import NavRight from "./NavRight";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { getAllEndUsers, getUsers } from "../../apiCalls/allApiCalls";
import SearchIcon from "@mui/icons-material/Search";
import { useTheme } from "@emotion/react";
import { tileShadows } from "../../pages/Root";
const Navbar = () => {
  const { loading: translationsLoading, translations } = useSelector(
    (state) => state.translations
  );
  const { loading: userLangLoading, language: userLang } = useSelector(
    (state) => state.userLanguage
  );
  const { loading: profileLoading, user: profile } = useSelector(
    (state) => state.users
  );
  const { loading: allTranslationsLoading, allTranslations } = useSelector(
    (state) => state.translations
  );
  const [fullNameCode, setFullNameCode] = useState("");
  const theme = useTheme();
  const navigate = useNavigate();
  const [usersData, setUsersData] = useState([]);
  const [inputValue, setInputValue] = useState("");
  // const userDataMap = sessionStorage.get()
  const { allUsers: userDataMap } = useSelector((state) => state.users);
  const [selectedUid, setSelectedUid] = useState("");
  const location = useLocation();

  useEffect(() => {
    getAllUsers();
  }, []);

  useEffect(() => {
    if (allTranslations && allTranslations.length > 0 && profile) {
      // console.log("triggered");
      const fn = profile?.FirstName?.replace(/ /g, "_");
      const ln = profile?.LastName?.replace(/ /g, "_");
      const code = `${fn?.toLowerCase()}_${ln?.toLowerCase()}`;
      const fullName = allTranslations[code];
      // console.log("fn", fn, ", ln", ln);
      if (fullName) {
        // console.log("fullname", fullName);
        setFullNameCode(fullName[userLang]);
      }
    }
  }, [allTranslations, profile, userLang]);

  const getAllUsers = async () => {
    const response = await getAllEndUsers();
    // const response = await getUsers();
    if (response) {
      setUsersData(response?.data);
    } else {
      setUsersData([]);
    }
  };

  console.log(profile, "Input value before state");

  return (
    <Container
      maxWidth="false"
      disableGutters
      // sx={{
      //   background:
      //     "linear-gradient(to left bottom, rgb(34, 52, 52), rgb(39, 103, 86))",
      // }}
    >
      <AppBar
        position="static"
        elevation={0}
        sx={{
          bgcolor: "transparent",
        }}
      >
        <Toolbar
          sx={{
            justifyContent: "space-between",
            px: 5,
            // py: 1,
            mt: 2,
          }}
          disableGutters
        >
          {/* NAV LEFT */}
          {/* <Link href={"/"} style={{ "text-decoration": "none" }}> */}
          <Typography
            variant="h4"
            component="div"
            // width={"150%"}
            sx={{
              color: theme.palette.text.bold,
              fontWeight: 600,
              // fontFamily: "Lucida Console",
              fontSize: {
                md: 24,
                sm: 24,
                xs: 24,
              },
            }}
          >
            {allTranslations?.hello?.[userLang] ?? "Hello"},{" "}
            {/* {allTranslations?.[
              `${profile?.FirstName?.toLowerCase()}_${profile.LastName?.toLowerCase()}`
            ]?.[userLang] ?? `${profile?.FirstName} ${profile?.LastName}`}{" "} */}
            {fullNameCode && fullNameCode.length > 0
              ? fullNameCode
              : `${profile?.FirstName} ${profile?.LastName}`}{" "}
          </Typography>
          {/* </Link> */}
          <Stack direction={"row"} gap={5}>
            {/* SEARCH */}
            <Box sx={{ width: 450 }}>
              <Autocomplete
                open={inputValue.length > 0}
                value={inputValue}
                freeSolo
                disableClearable
                onChange={(e, newValue) => {
                  if (newValue) {
                    const temp = newValue.split(",");

                    const uid = temp[1]?.trim();

                    if (userDataMap && userDataMap[uid]) {
                      setSelectedUid(uid);
                      const stateValue = { uid };
                      sessionStorage.setItem("orgChartUser", uid);
                      if (location.pathname === "/reference-user") {
                        window.location.reload();
                      } else {
                        navigate("/reference-user", { state: selectedUid });
                      }
                      setInputValue(""); // Clear input value
                    } else {
                      console.log("uid not found");
                    }
                  }
                }}
                onKeyDown={(e, newValue) => {
                  if (e.key === "Enter" && newValue) {
                    e.preventDefault();
                    // Additional logic for 'Enter' key if needed
                  }
                }}
                onBlur={() => {
                  setInputValue(""); // Clear search when moving away
                }}
                onInputChange={(event, newInputValue) => {
                  setInputValue(newInputValue);
                }}
                options={usersData.map((option) => {
                  return `${option.FullName}, ${option.Uid}`;
                })}
                filterOptions={(options, state) => {
                  return options.filter((option) =>
                    option
                      ?.toLowerCase()
                      .includes(state.inputValue?.toLowerCase())
                  );
                }}
                renderInput={(params) => (
                  <InputBase
                    {...params.InputProps}
                    sx={{
                      borderRadius: "30px",
                      py: 1,
                      pl: 3,
                      pr: 1,
                      backgroundColor: theme.palette.primary.mainGradient2,
                      color: theme.palette.text.light,
                      width: "100%",
                      border: `1px solid ${theme.palette.primary.border}`,
                      ...tileShadows,
                    }}
                    inputProps={{
                      ...params.inputProps,
                      type: "search",
                    }}
                    placeholder={
                      allTranslations?.search_employee?.[userLang] ??
                      "Search employee"
                    }
                    endAdornment={
                      <InputAdornment
                        position="start"
                        sx={{
                          color: theme.palette.text.contrastText,
                        }}
                      >
                        <SearchIcon
                          fontSize="large"
                          sx={{
                            borderRadius: 5,
                            bgcolor: theme.palette.primary.main,
                            p: 0.5,
                          }}
                        />
                      </InputAdornment>
                    }
                  />
                )}
              />
            </Box>
            {/* NAV RIGHT */}
            <Stack
              direction={"row"}
              spacing={2}
              sx={{
                display: {
                  xs: "none",
                  sm: "flex",
                },
              }}
            >
              <NavRight />
            </Stack>
          </Stack>

          {/* RESPONSIVE BURGER MENU */}
          {/* <Box
            sx={{
              display: { xs: "block", sm: "none" },
              ":hover": {
                cursor: "not-allowed",
              },
            }}
            style={{
              cursor: "not-allowed",
            }}
          >
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              // onClick={handleOpenNavMenu}
              color="inherit"
            >
              {!anchorElNav ? (
                <MenuIcon />
              ) : (
                <CloseIcon
                  sx={{
                    ":hover": {
                      cursor: "not-allowed",
                    },
                  }}
                />
              )}
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", sm: "none" },
                marginTop: "-10px",
                marginLeft: "-23px",
              }}
            >
              {pages.map((page) => (
                <MenuItem
                  key={page}
                  onClick={handleCloseNavMenu}
                  sx={{
                    display: {
                      sm: "none",
                    },
                  }}
                >
                  <Link
                    style={{
                      color: "#000",
                    }}
                    href={`/${page.toLowerCase().split(" ").join("")}`}
                  >
                    <Typography
                      textAlign="center"
                      sx={{
                        fontSize: "12px",
                      }}
                    >
                      {page}
                    </Typography>
                  </Link>
                </MenuItem>
              ))}
            </Menu>
          </Box> */}
        </Toolbar>
      </AppBar>
    </Container>
  );
};

export default Navbar;

import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  Autocomplete,
  InputAdornment,
  InputBase,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import BreadCrumbs from "../components/common/breadCrumbs/BreadCrumbs";
import UserDetails from "../components/common/approver/UserDetails";
import RequestDetails from "../components/common/approver/RequestDetails";
import RequestsDetailsTable from "../components/common/approver/RequestsDetailsTable";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  approveDeprovisioning,
  disableProductsUnlock,
  fetchRequestDetails,
  forwardComplianceRequest,
  getUserDetailsByUid,
  updateRequest,
  updateRequestStatus,
} from "../apiCalls/allApiCalls";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";

import RisksTable from "../components/compliance/RisksTable";
import { Stack } from "@mui/system";
import { toast } from "react-toastify";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ApproveDrawer from "../components/approver/ApproverDrawer";
import { useSelector } from "react-redux";
import { getAllEndUsers } from "../apiCalls/allApiCalls";
import { tileShadows } from "./Root";
import SearchIcon from "@mui/icons-material/Search";
import { useTheme } from "@emotion/react";
import Modal from "@mui/material/Modal";
import FeedbackForm from "../components/feedbackform/FeedbackForm";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 520,
  minHeight: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const roleSystemAssignments = [
  {
    system: "A28CLNTOOO",
    role: "GR01 - Role Admin |vs.| Rule Set Approval - Functional (Risk Level)",
    resolve: "Mitigation 1",
  },
  {
    system: "A05CLNT050",
    role: "GR02 - Role Designer |vs.| Rule Set Approval - Functional (Risk Level)",
    resolve: "Mitigation 2",
  },
  {
    system: "A1 UCLNT300",
    role: "GR03 - Role Designer |vs.| Role Admin",
    resolve: "Mitigation 3",
  },
  {
    system: "A28CLNT050",
    role: "GR06 - Role Owner |vs.| Role Admin",
    resolve: "Mitigation 1",
  },
  {
    system: "A05CLNTOOO",
    role: "GR07 - GRAC Access Request Admin |vs.| Access Request Approver",
    resolve: "Mitigation 2",
  },
  {
    system: "A28CLNTOOO",
    role: "GR08 - GRAC Access Request Admin |vs.| Rule Set Approval - Technical (Function Level)",
    resolve: "Mitigation 3",
  },
  {
    system: "A05CLNT050",
    role: "GR09 - GRAC Access Request Admin |vs.| Rule Set Approval - Functional (Risk Level)",
    resolve: "Mitigation 1",
  },
];

const ComplianceDetails = () => {
  const location = useLocation();
  const { loading: allTranslationsLoading, allTranslations } = useSelector(
    (state) => state.translations
  );
  const { language: userLang } = useSelector((state) => state.userLanguage);
  const { user: profile } = useSelector((state) => state.users);

  const navigate = useNavigate();
  const [feedbackOpen, setFeedbackOpen] = useState(false);
  const handleFeedbackOpen = () => setFeedbackOpen(true);
  const params = useParams();
  const [details, setDetails] = useState();
  const [userDetails, setUserDetails] = useState();
  const [loading, setLoading] = useState(true);
  const [allRiskResolved, setAllRiskResolved] = useState(false);
  const [buttonsEnabled, setButtonsEnabled] = useState(false);
  const [open, setOpen] = useState(false);
  const [drawerType, setDrawerType] = useState("");
  const toggleDrawer = (open) => () => {
    setOpen(open);
  };
  const { state: receivedData } = useLocation();
  //For Approver Drawer
  const [inputValue, setInputValue] = useState("");

  // Local States for Feeback Modals management
  const [openForwardSec, setOpenForwardSec] = useState(false);

  // ssssssssssssssssssssssssssssssssssssssssssssssssssssssssssss
  const [mitigations, setMitigations] = useState({});
  const [rows, setRows] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  console.log(selectedRows, "seleceted rows");
  const [toastOpen, setToastOpen] = useState(false);
  const handleChangeMitigation = (event, rowId) => {
    setMitigations((prev) => ({
      ...prev,
      [rowId]: event.target.value,
    }));
  };

  const handleSelectionChange = (newSelectionModel) => {
    // const selectedRowData = newSelectionModel.map((id) =>
    //   requestDetialsRows.find((row) => row.id === id)
    // );
    //  (selectedRowData);
    // const allRiskResolved = checkIfAllRiskResolved(selectedRowData);
    // setButtonsEnabled(allRiskResolved);
    // console.log("Selected Rows:", selectedRowData); // Debugging purposes
  };

  useEffect(() => {
    const initialRows =
      details?.requestData.productsData
        ?.map((row, index) => {
          const matchingAssignment = roleSystemAssignments.find(
            (assignment) =>
              assignment.system === row.productDetails?.system?.[0]
          );

          return {
            id: index,
            userId: details?.raisedForId || details?.requestorId,
            system: row.productDetails?.system?.[0],
            riskDescription: matchingAssignment
              ? matchingAssignment.role
              : "No role assigned",
            platform: row.productDetails?.platform?.[0],
            risk: row.risk || row.productDetails?.risk,
            id: index,
            productName: row.productName || row.productDetails.productName,
            status:
              row.status === "movedToCompliance"
                ? "Moved To Compliance"
                : row.status || "In Progress",
            validFrom: row.validFrom, // Dates in the format DD-MM-YYYY
            validTill: row.validTill,
            approver: row.approver || row.productDetails.approver.toUpperCase(),
            productID: row.productId,
            risk: row.risk || row.productDetails.risk,
          };
        })
        .filter(
          (rowData) =>
            rowData.risk === true && rowData.status == "Moved To Compliance"
          // (rowData) => console.log(rowData, "rowData")
        ) || [];

    setRows(initialRows);
  }, [details]);

  // const handleRunRiskAnalysis = () => {
  //   setLoading(true);
  //   toast.info("Run Risk Analysis Started");

  //   setTimeout(() => {
  //     let allResolved = true; // Temporary variable

  //     const updatedRows = selectedRows.map((row) => {
  //       const matchingAssignment = roleSystemAssignments.find(
  //         (assignment) => assignment.system === row.system
  //       );

  //       if (
  //         matchingAssignment &&
  //         mitigations[row.id] === matchingAssignment.resolve
  //       ) {
  //         return { ...row, risk: "false" };
  //       } else {
  //         allResolved = false; // If any row's risk is not resolved, set to false
  //         return row;
  //       }
  //     });
  //     setRows(updatedRows);

  //     setSelectedRows(updatedRows);

  //     setAllRiskResolved(allResolved); // Update state after the loop
  //     if (allResolved) {
  //       toast.success("All risks resolved");
  //     } else {
  //       toast.error(
  //         <div>
  //           Risk analysis failed.
  //           <a
  //             href="mailto:ganeshg@aapmor.com"
  //             style={{ color: "blue", textDecoration: "underline" }}
  //           >
  //             contact support
  //           </a>
  //           .
  //         </div>
  //       );
  //     }

  //     setLoading(false);
  //   }, 2000);
  // };
  const handleRunRiskAnalysis = () => {
    setLoading(true);
    toast.info("Run Risk Analysis Started");

    setTimeout(() => {
      let allResolved = true; // Temporary variable to track resolution status

      // Update only the rows present in selectedRows based on risk analysis
      const updatedRows = rows.map((row) => {
        const isRowSelected = selectedRows.some(
          (selectedRow) => selectedRow.id === row.id
        );

        if (isRowSelected) {
          const matchingAssignment = roleSystemAssignments.find(
            (assignment) => assignment.system === row.system
          );

          if (
            matchingAssignment &&
            mitigations[row.id] === matchingAssignment.resolve
          ) {
            return { ...row, risk: "false" }; // Update risk status
          } else {
            allResolved = false; // Set to false if any selected row's risk is not resolved
            return row;
          }
        }
        return row; // Return the row unchanged if it's not selected
      });

      setRows(updatedRows); // Update the entire rows array
      setSelectedRows(
        updatedRows.filter((row) =>
          selectedRows.some((selectedRow) => selectedRow.id === row.id)
        )
      ); // Update selectedRows with resolved rows

      setAllRiskResolved(allResolved); // Update the resolved state after the loop

      if (allResolved) {
        toast.success("All risks resolved");
      } else {
        toast.error(
          <div>
            Risk analysis failed.
            <a
              href="mailto:ganeshg@aapmor.com"
              style={{ color: "blue", textDecoration: "underline" }}
            >
              contact support
            </a>
            .
          </div>
        );
      }

      setLoading(false);
    }, 2000);
  };

  const handleValidate = (type) => {
    setDrawerType(type); // Set the type of drawer being opened
    toggleDrawer(true)();
  };

  async function handleForwardRequest(newApprovalId) {
    let oldApprovalId;
    // forward the request from profile?.Uid to newApprovalId
    if (receivedData && receivedData.isDelegation) {
      oldApprovalId = receivedData.delegatorId;
      console.log(
        `Selected Approver where request ${params.reqId} is getting forwarded to ${newApprovalId} from ${receivedData.delegatorId}`
      );
    } else {
      oldApprovalId = profile?.Uid;
      console.log(
        `Selected Approver where request ${params.reqId} is getting forwarded to ${newApprovalId} from ${profile?.Uid}`
      );
    }

    try {
      const res = await forwardComplianceRequest(
        params.reqId,
        oldApprovalId,
        newApprovalId
      );
      navigate("/approver/requests");
    } catch (error) {
      console.error(error);
    }
  }
  // sssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssss
  useEffect(() => {
    // Fetch both the request details and user details
    const getRequestDetails = async () => {
      try {
        const response = await fetchRequestDetails(params.reqId);
        if (response?.status === 200) {
          console.log(response.data, "Responce");
          setDetails(response.data);
        } else {
          throw new Error("Failed to fetch request details");
        }
      } catch (error) {
        console.error("Failed to fetch request details:", error);
      }
    };
    console.log(details);
    const getUsersDetails = async (requestorId) => {
      try {
        const response = await getUserDetailsByUid(requestorId);
        if (response?.status === 200) {
          setUserDetails(response.data);
        } else {
          throw new Error("Failed to fetch user details");
        }
      } catch (error) {
        console.error("Failed to fetch user details:", error);
      }
    };

    const fetchData = async () => {
      await getRequestDetails();

      if (details) {
        let id = details.raisedForId
          ? details.raisedForId
          : details.requestorId;
        await getUsersDetails(id);
      }
      // Set loading to false once both requests are completed
      setLoading(false);
    };

    fetchData();
  }, [params.reqId, details?.requestorId]);
  const today = dayjs().startOf("day");
  const columns = [
    {
      headerClassName: "super-app-theme--header",
      field: "productName",
      headerName: allTranslations?.product_names?.[userLang] ?? "Product Names",
      flex: 1,
      width: 500,
      resizable: false,
      sortable: false,
    },
    {
      headerClassName: "super-app-theme--header",
      field: "status",
      headerName: allTranslations?.status?.[userLang] ?? "Status",
      flex: 1,
      resizable: false,
      sortable: false,
      width: 150,
    },
    {
      headerClassName: "super-app-theme--header",
      field: "approver",
      headerName: allTranslations?.approver?.[userLang] ?? "Approver",
      flex: 1,
      resizable: false,
      sortable: false,
      width: 150,
    },
    ...(details?.requestType !== "Deprovisioning User"
      ? [
          {
            headerClassName: "super-app-theme--header",
            field: "validFrom",
            headerName: allTranslations?.valid_from?.[userLang] ?? "Valid From",
            flex: 1,
            resizable: false,
            sortable: false,
            width: 150,
            renderCell: (params) => (
              <Box mt={1}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    // minDate={today}
                    inputProps={{ disableUnderline: true }}
                    value={
                      params.row.validFrom ? dayjs(params.row.validFrom) : null
                    }
                    onChange={(newDate) => {
                      // Handle the date change logic here
                      console.log("New Valid From Date:", newDate);
                    }}
                    slotProps={{ textField: { variant: "standard" } }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        inputProps={{ disableUnderline: true }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Box>
            ),
          },
          {
            headerClassName: "super-app-theme--header",
            field: "validTill",
            headerName: allTranslations?.valid_till?.[userLang] ?? "Valid Till",
            flex: 1,
            resizable: false,
            sortable: false,
            width: 150,
            renderCell: (params) => (
              <Box mt={1}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    slotProps={{ textField: { variant: "standard" } }}
                    // minDate={today}
                    value={
                      params.row.validTill
                        ? dayjs(params.row.validTill, "DD-MM-YYYY")
                        : null
                    }
                    onChange={(newDate) => {
                      // Handle the date change logic here
                      console.log("New Valid Till Date:", newDate);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={false} // Ensure there is no error outline
                        helperText="" // Clear any potential helper text
                      />
                    )}
                  />
                </LocalizationProvider>
              </Box>
            ),
          },
          {
            headerClassName: "super-app-theme--header",
            field: "risk",
            headerName: allTranslations?.risk?.[userLang] ?? "Risk",
            renderCell: (params) => (
              <Box mt={1}>
                {params.value === "false" ? (
                  <CheckCircleIcon sx={{ color: "green" }} />
                ) : (
                  <ReportGmailerrorredIcon sx={{ color: "tomato" }} />
                )}
              </Box>
            ),
          },
        ]
      : []),
  ];
  const hasRisks = () => {
    return rows.some((row) => row.risk === "true");
  };

  const requestDetialsRows =
    details?.requestData.productsData
      ?.map((row, index) => ({
        id: index,
        productName: row.productName || row.productDetails.productName,
        status:
          row.status === "movedToCompliance"
            ? "Moved To Compliance"
            : row.status || "In Progress",
        validFrom: row.validFrom, // Dates in the format DD-MM-YYYY
        validTill: row.validTill,
        approver: row.approvalID || row.productDetails.approvalID,
        productID: row.productId,
        risk: row.risk || row.productDetails.risk,
      }))
      .filter((rowData) => rowData.risk === true) || [];

  // $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
  const getMainStatus = (rows) => {
    const statuses = rows.map((row) => row.status);

    if (statuses.every((status) => status === "Approved")) {
      return "Approved";
    }

    if (statuses.every((status) => status === "Rejected")) {
      return "Rejected";
    }

    if (
      statuses.some((status) => status === "Approved") &&
      statuses.some((status) => status === "Rejected")
    ) {
      return "In Progress";
    }

    return "In Progress"; // Default case if none of the conditions match
  };
  const handleApprove = async (requestType, selectedRows) => {
    try {
      // Update status of selected rows to "Approved"
      const updatedRows = rows.map((row) =>
        selectedRows.some((selected) => selected.id === row.id)
          ? { ...row, status: "Approved" }
          : row
      );
      console.log(updatedRows, "updatedRowshandleapprove");

      const body = {
        requestID: details?.requestId,
        productDetails: selectedRows.map((row) => ({
          productid: row.productID,
          status: "Approved",
          approverID: profile.Uid,
          approvercomments: "Approved by compliance manager", // handle for compliance manager as well
        })),
      };

      console.log(body, "hello");

      // Handle other request types
      const response = await updateRequest(details?.requestId, body);

      if (response.status === 201) {
        // Determine the main request status including all rows
        const mainStatus = getMainStatus(updatedRows);

        // Update the main request status
        const mainRequestBody = {
          requestID: details?.requestId,
          mainStatus,
          approverID: profile.Uid,
        };
        const statusResponse = await updateRequestStatus(
          details?.requestId,
          mainRequestBody
        );

        if (statusResponse.status === 201) {
          toast.success("Request(s) Approved successfully.");
          // navigate("/compliance");
          handleFeedbackOpen();
        }
      }
    } catch (error) {
      toast.error(
        <div>
          Failed to Approve the request(s)
          <a
            href="mailto:ganeshg@aapmor.com"
            style={{ color: "blue", textDecoration: "underline" }}
          >
            contact support
          </a>
          .
        </div>
      );
      console.error("Failed to Approve the request(s)", error);
    }
  };
  const handleReject = async (requestType, selectedRows) => {
    try {
      // Update status of selected rows to "Rejected"
      const updatedRows = rows.map((row) =>
        selectedRows.some((selected) => selected.id === row.id)
          ? { ...row, status: "Rejected" }
          : row
      );

      const body = {
        requestID: details?.requestId,
        productDetails: selectedRows.map((row) => ({
          productid: row.productID,
          status: "Rejected",
          approvercomments: "Rejected by approver",
        })),
      };

      const response = await updateRequest(details?.requestId, body);

      if (response.status === 201) {
        // Determine main request status including all rows
        const mainStatus = getMainStatus(updatedRows);

        // Update the main request status
        const mainRequestBody = {
          requestID: details?.requestId,
          mainStatus,
        };

        await updateRequestStatus(details?.requestId, mainRequestBody);

        toast.success("Request(s) Rejected successfully.");
        // navigate("/compliance");
        handleFeedbackOpen();
      }
    } catch (error) {
      toast.error(
        <div>
          Failed to Reject the request(s)
          <a
            href="mailto:ganeshg@aapmor.com"
            style={{ color: "blue", textDecoration: "underline" }}
          >
            contact support
          </a>
          .
        </div>
      );
      console.error("Failed to Reject the request(s)", error);
    }
  };
  return (
    <Box>
      <Container
        sx={{
          p: 4,
          bgcolor: "background.default",
          borderRadius: 5,
          minHeight: "100vh",
          minWidth: "90vw",
          mt: 3,
          ml: 3,
        }}
      >
        <Box mb={3}>
          <BreadCrumbs />
        </Box>
        <UserDetails details={details} userDetails={userDetails} />
        <RequestDetails details={details} />
        <RequestsDetailsTable
          rows={rows}
          columns={columns}
          setSelectedRows={setSelectedRows}
          handleSelectionChange={handleSelectionChange}
        />
        <RisksTable
          mitigations={mitigations}
          handleChangeMitigation={handleChangeMitigation}
          handleRunRiskAnalysis={handleRunRiskAnalysis}
          loading={loading}
          rows={selectedRows}
        />
        <Stack
          direction="row"
          justifyContent="flex-end"
          spacing={2}
          mt={3}
          mr={4}
        >
          <Button
            variant="contained"
            color="success"
            onClick={() => handleValidate("approve")}
            // disabled={selectedRows.length === 0}
            disabled={!allRiskResolved}

            // onClick={toggleDrawer(true)}
          >
            {allTranslations?.approve?.[userLang] ?? "Approve"}
          </Button>
          <Button
            variant="outlined"
            // sx={{ color: "white", borderColor: "white" }}
            // onClick={handleReject}
            onClick={() => handleValidate("reject")}
            disabled={!allRiskResolved}
          >
            {allTranslations?.reject?.[userLang] ?? "Reject"}
          </Button>
          {/* <Button variant="outlined">
            {allTranslations?.forward?.[userLang] ?? "Forward"}
          </Button> */}
          <SearchApproverModal
            value={allTranslations?.forward?.[userLang] ?? "Forward"}
            setOpenForwardSec={setOpenForwardSec}
            inputValue={inputValue}
            setInputValue={setInputValue}
            receivedData={receivedData}
            profile={profile}
            handleValidate={handleValidate}
          />
          <ForwardCommentsModal
            openForwardSec={openForwardSec}
            setOpenForwardSec={setOpenForwardSec}
            inputValue={inputValue}
            setInputValue={setInputValue}
            handleForwardRequest={handleForwardRequest}
          />
        </Stack>
      </Container>
      <ApproveDrawer
        open={open}
        setOpen={setOpen}
        toggleDrawer={toggleDrawer}
        selectedRows={selectedRows}
        type={drawerType}
        requestType={details?.requestType}
        handleApprove={handleApprove}
        handleReject={handleReject}
        inputValue={inputValue}
        handleForwardRequest={handleForwardRequest}
        requestId={params.reqId}
        // type = type === "Approver" ? "Approver" : "Reject"
      />
      <FeedbackForm
        path={location.pathname}
        requestType={"compliance"}
        open={feedbackOpen}
        setOpen={setFeedbackOpen}
        handleOpen={handleFeedbackOpen}
      />
    </Box>
  );
};

const SearchApproverModal = ({
  value,
  setOpenForwardSec,
  inputValue,
  setInputValue,
  receivedData,
  profile,
  handleValidate,
}) => {
  // Modal States
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // Search Approver States
  const theme = useTheme();
  const [selectedUid, setSelectedUid] = useState("");
  const [usersData, setUsersData] = useState([]);

  const { allUsers: userDataMap } = useSelector((state) => state.users);
  const { loading: userLangLoading, language: userLang } = useSelector(
    (state) => state.userLanguage
  );
  const { loading: allTranslationsLoading, allTranslations } = useSelector(
    (state) => state.translations
  );

  useEffect(() => {
    getAllUsers();
  }, []);

  const getAllUsers = async () => {
    const response = await getAllEndUsers();
    // const response = await getUsers();
    let approvers = [
      "U0012",
      "U0020",
      "U0024",
      "U0025",
      "U0026",
      "U0002",
      "U0030",
      "U0029",
      "U0036",
    ];

    const filteredApprovers = approvers.filter((approver) => {
      if (receivedData && receivedData.isDelegation) {
        // Filter out `receivedData.delegatorId` if `isDelegation` is true
        return approver !== receivedData.delegatorId;
      } else {
        // Filter out `profile?.Uid` if `isDelegation` is not true
        return approver !== profile?.Uid;
      }
    });

    if (response) {
      const onlyApprovers = response?.data.filter((user) => {
        if (filteredApprovers.includes(user.Uid)) {
          return user;
        }
      });
      setUsersData(onlyApprovers);
    } else {
      setUsersData([]);
    }
  };

  return (
    <>
      <Button variant="outlined" onClick={() => handleOpen()}>
        {value}
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Stack gap={1.5} sx={style}>
          <Typography
            sx={{
              color: "primary.main",
            }}
          >
            {allTranslations?.forward_request?.[userLang] ?? "Forward request"}
          </Typography>
          {/* SEARCH */}
          <Box sx={{ width: 450 }}>
            <Autocomplete
              open={inputValue.length > 0}
              value={inputValue}
              freeSolo
              disableClearable
              onChange={(e, newValue) => {
                console.log("triggered onChange", newValue);
                if (newValue) {
                  const temp = newValue.split(",");
                  console.log("temp", temp);
                  // setOpenForwardSec(true);
                  setInputValue(temp);
                  const uid = temp[1]?.trim();
                  console.log("uid", uid);
                  if (userDataMap && userDataMap[uid]) {
                    console.log("uid found", userDataMap[uid]);
                    setSelectedUid(uid);
                    const stateValue = { uid };
                    // sessionStorage.setItem("orgChartUser", uid);
                    // if (location.pathname === "/reference-user") {
                    //   window.location.reload();
                    // } else {
                    //   navigate("/reference-user", { state: selectedUid });
                    // }
                    // setInputValue(""); // Clear input value
                    handleValidate("forward");
                    handleClose();
                  } else {
                    console.log("uid not found");
                  }
                }
              }}
              onKeyDown={(e, newValue) => {
                if (e.key === "Enter" && newValue) {
                  e.preventDefault();
                  // Additional logic for 'Enter' key if needed
                }
              }}
              onBlur={() => {
                setInputValue(""); // Clear search when moving away
              }}
              onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
                console.log("input change", newInputValue);
              }}
              options={usersData.map((option) => {
                return `${option.FullName}, ${option.Uid}`;
              })}
              filterOptions={(options, state) => {
                return options.filter((option) =>
                  option
                    ?.toLowerCase()
                    .includes(state.inputValue?.toLowerCase())
                );
              }}
              renderInput={(params) => (
                <InputBase
                  {...params.InputProps}
                  sx={{
                    borderRadius: "6px",
                    py: 1,
                    pl: 3,
                    pr: 1,
                    backgroundColor: theme.palette.primary.mainGradient2,
                    color: theme.palette.text.light,
                    width: "100%",
                    border: `1px solid ${theme.palette.primary.border}`,
                    ...tileShadows,
                  }}
                  inputProps={{
                    ...params.inputProps,
                    type: "search",
                  }}
                  placeholder={
                    allTranslations?.search_approver?.[userLang] ??
                    "Search Approver"
                  }
                  endAdornment={
                    <InputAdornment
                      position="start"
                      sx={{
                        color: theme.palette.text.primary,
                      }}
                    >
                      <SearchIcon
                        fontSize="large"
                        sx={{
                          borderRadius: 5,
                          p: 0.5,
                        }}
                      />
                    </InputAdornment>
                  }
                />
              )}
            />
          </Box>
        </Stack>
      </Modal>
    </>
  );
};

const ForwardCommentsModal = ({
  openForwardSec,
  setOpenForwardSec,
  inputValue,
  setInputValue,
  handleForwardRequest,
}) => {
  const [comments, setComments] = useState("");
  const theme = useTheme();
  const handleClose = () => {
    setOpenForwardSec(false);
    setInputValue("");
    setComments("");
  };

  return (
    <Modal
      open={openForwardSec}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Stack
        gap={4}
        sx={{
          ...style,
          px: 10,
          py: 6,
          width: "600px",
          border: "1px solid black",
          borderColor: "primary.border",
        }}
      >
        <Typography
          sx={{
            color: "text.primary",
          }}
        >
          Do you want to forward selected items to user {inputValue[0]}(
          {inputValue[1]}) ?
        </Typography>
        <Stack gap={1.5}>
          <Typography
            sx={{
              color: "text.primary",
              fontWeight: 200,
            }}
          >
            comments <span style={{ color: "red" }}>*</span>
          </Typography>

          <TextField
            variant="standard"
            InputProps={{
              disableUnderline: true,
            }}
            placeholder={
              // allTranslations?.type_your_comments_here?.[userLang] ??
              "Type your comments here..."
            }
            value={comments}
            onChange={(e) => setComments(e.target.value)}
            multiline
            sx={{
              width: "100%",
              minHeight: 100,
              maxHeight: 150,
              overflowY: "auto",
              "&::-webkit-scrollbar": {
                width: "2px",
                bgcolor: theme.palette.icons.bgLight,
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "icons.bgDark",
                borderRadius: 5,
              },
              p: 1.5,
              border: "1px solid gray",
            }}
          />
          <Stack direction={"row"} justifyContent={"space-between"} mt={2}>
            <Button
              variant="contained"
              disableElevation
              sx={{
                border: "1.5px solid black",
                borderColor: "primary.border",
                borderRadius: "5px",
                bgcolor: "text.lighter",
                color: "text.primary",
                px: 3,
                py: 0,
                fontSize: "12px",
                height: "25px",
              }}
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              disableElevation
              sx={{
                bgcolor: "primary.main",
                px: 3,
                py: 0,
                fontSize: "12px",
                height: "25px",
              }}
              disabled={comments ? false : true}
              onClick={() => {
                handleForwardRequest(inputValue[1].trim());
                handleClose();
              }}
            >
              Yes
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Modal>
  );
};

export default ComplianceDetails;

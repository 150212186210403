import React, { useEffect, useState } from "react";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";

import {
  Button,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import { Link, useLocation } from "react-router-dom";
import { useTheme } from "@mui/system";
import { useSelector } from "react-redux";

const ProductsTable = ({
  data, //filteredProducts displayed in the table
  initial, // state to check the request page status
  setCart,
  cart,
  addedItems,
  setAddedItems,
  setFilteredProducts,
  isLoading,
  requestType,
}) => {
  const { loading: allTranslationsLoading, allTranslations } = useSelector(
    (state) => state.translations
  );
  const { language: userLang } = useSelector((state) => state.userLanguage);
  const [dates, setDates] = useState({});
  const [dataLoading, setDataLoading] = useState(true);
  const [editingApprover, setEditingApprover] = useState(null); // Track which approver is being edited
  const [approverValue, setApproverValue] = useState({});
  const today = dayjs().startOf("day");
  const maxDate = dayjs("2099-12-31");
  // let dayCount = 5; //default days count
  const theme = useTheme();
  const { pathname } = useLocation();

  function getEndDate(startDate, daysToAdd) {
    let endDate = dayjs(startDate); // Initialize with the start date
    let addedDays = 0;

    while (addedDays < daysToAdd) {
      endDate = endDate.add(1, "day"); // Add one day at a time
      // Check if it's a weekday (Monday to Friday)
      if (endDate.day() !== 0 && endDate.day() !== 6) {
        addedDays++;
      }
    }

    return endDate; // Return the end date in 'YYYY-MM-DD' format
  }

  // initial data from parent & updated final data which has additional properties of dates & status - rows
  const finalData = data.map((item) => ({
    ...item,
    status: "In Progress",
    validFrom: dates[item.productId]?.from
      ? dayjs(dates[item.productId].from).format("DD-MM-YYYY")
      : dayjs(today),
    validTill: dates[item.productId]?.till
      ? dayjs(dates[item.productId].till).format("DD-MM-YYYY")
      : pathname.includes("/elevated-request")
      ? dayjs(today).add(1, "day").format("DD-MM-YYYY")
      : maxDate.format("DD-MM-YYYY"),
  }));

  useEffect(() => {
    if (Object.keys(dates).length !== 0) {
      setFilteredProducts(finalData);
    }
    // async function fetchData() {
    // setFilteredProducts(finalData);
    // setDataLoading(false);
    // }
    // fetchData();
  }, [dates]);

  const handleAddtoCart = (item) => {
    setCart([...cart, item]);
    setAddedItems((prev) => ({ ...prev, [item.productId]: true }));
  };

  const handleDateChange = (date, productId, type) => {
    setDates((prev) => ({
      ...prev,
      [productId]: {
        ...prev[productId],
        [type]: date,
      },
    }));
  };
  const handleApproverClick = (productId, currentApprover) => {
    setEditingApprover(productId); // Set the current product as being edited
    setApproverValue((prev) => ({
      ...prev,
      [productId]: currentApprover || "", // Set the current approver value or empty if none
    }));
  };

  const handleApproverChange = (event, productId) => {
    setApproverValue((prev) => ({
      ...prev,
      [productId]: event.target.value, // Update the approver value as user types
    }));
  };

  const handleApproverBlur = (productId) => {
    setEditingApprover(null); // Exit edit mode when the field loses focus
    setFilteredProducts((prev) =>
      prev.map((item) =>
        item.productId === productId
          ? {
              ...item,
              productDetails: {
                ...item.productDetails,
                approvalID: approverValue[productId],
              },
            }
          : item
      )
    );
  };
  const shouldDisableDate = (date, type, otherDate) => {
    const day = date.day();

    // Disable weekends (Saturday=6, Sunday=0)
    if (day === 0 || day === 6) {
      return true;
    }

    if (type === "from" && otherDate < date) {
      return true;
    }
    if (type === "till" && otherDate > date) {
      return true;
    }
  };

  // Data-grid rows & columns data
  const rows = finalData.map((e, i) => ({
    ...e,
    id: i + 1,
    sno: i + 1,
    actions: "Add to cart",
  }));

  const columns = [
    {
      field: "sno",
      headerClassName: "super-app-theme--header",
      headerName: "S.NO.",
      headerAlign: "center",
      flex: 1,
      resizable: false,
      sortable: false,
      renderCell: (params) => (
        <Box
          sx={{
            height: 42,
            width: 42,
            borderRadius: "50%",
            bgcolor: "primary.main",
            color: "white",
            m: "auto",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            mt: 2,
          }}
        >
          #{params.value}
        </Box>
      ),
    },
    {
      field: "productName",
      headerClassName: "super-app-theme--header",
      headerName: allTranslations?.product_name?.[userLang] ?? "PRODUCT NAME",
      headerAlign: "center",
      flex: 1,
      resizable: false,
      sortable: false,
      renderCell: (params) => (
        <Tooltip title={params.row.productDetails.productName} arrow>
          <Typography
            sx={{
              mt: 3,
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis", // Truncate text with ellipsis
              maxWidth: 150, // Limit the width of the text, adjust based on your column size
            }}
          >
            {params.row.productDetails.productName}
          </Typography>
        </Tooltip>
      ),
    },
    {
      field: "productId",
      headerClassName: "super-app-theme--header",
      headerName: allTranslations?.product?.[userLang] + " ID" ?? "PRODUCT ID",
      headerAlign: "center",
      align: "center",
      flex: 1,
      resizable: false,
      sortable: false,
      renderCell: (params) => (
        <Typography sx={{ mt: 3 }}>
          {params.row.productDetails.productId}
        </Typography>
      ),
    },
    // Conditionally render validFrom and validTill columns
    ...(requestType !== "deprovisioning"
      ? [
          {
            field: "validFrom",
            headerClassName: "super-app-theme--header",
            headerName: allTranslations?.valid_from?.[userLang] ?? "VALID FROM",
            headerAlign: "center",
            type: "number",
            flex: 1,
            resizable: false,
            sortable: false,
            editable: true,
            renderCell: (params) => (
              <>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    sx={{ mt: 2 }}
                    label="Valid From"
                    minDate={today}
                    value={
                      dates[params.row.productDetails.productId]?.from ||
                      params.row?.validFrom
                    }
                    shouldDisableDate={(date) =>
                      shouldDisableDate(
                        date,
                        "from",
                        dates[params.row.productDetails.productId]?.till ||
                          params.row?.validTill
                      )
                    }
                    onChange={(date) =>
                      handleDateChange(
                        date,
                        params.row.productDetails.productId,
                        "from"
                      )
                    }
                    slotProps={{ textField: { variant: "standard" } }}
                  />
                </LocalizationProvider>
              </>
            ),
          },
          {
            field: "validTill",
            headerClassName: "super-app-theme--header",
            headerName: allTranslations?.valid_till?.[userLang] ?? "VALID TILL",
            headerAlign: "center",
            resizable: false,
            sortable: false,
            flex: 1,
            renderCell: (params) => (
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  sx={{ mt: 2 }}
                  label="Valid Till"
                  minDate={
                    pathname.includes("/elevated-request")
                      ? getEndDate(
                          dates[params.row.productDetails.productId]?.from,
                          1
                        )
                      : today.add(1, "day")
                  }
                  maxDate={
                    pathname.includes("/elevated-request")
                      ? dates[params.row.productDetails.productId]?.from
                        ? getEndDate(
                            dates[params.row.productDetails.productId]?.from,
                            5
                          )
                        : getEndDate(today, 5)
                      : null
                  }
                  shouldDisableDate={(date) =>
                    shouldDisableDate(
                      date,
                      "till",
                      dates[params.row.productDetails.productId]?.from
                    )
                  }
                  value={
                    pathname.includes("/elevated-request")
                      ? dates[params.row.productDetails.productId]?.till ||
                        getEndDate(
                          dates[params.row.productDetails.productId]?.from,
                          1
                        )
                      : dates[params.row.productDetails.productId]?.till || null
                  }
                  onChange={(date) =>
                    handleDateChange(
                      date,
                      params.row.productDetails.productId,
                      "till"
                    )
                  }
                  slotProps={{ textField: { variant: "standard" } }}
                />
              </LocalizationProvider>
            ),
          },
        ]
      : []),
    {
      field: "approver",
      headerClassName: "super-app-theme--header",
      headerName: allTranslations?.approver?.[userLang] ?? "APPROVER",
      resizable: false,
      headerAlign: "center",
      align: "center",
      sortable: false,
      flex: 1,
      renderCell: (params) =>
        editingApprover === params.row.productId ? (
          <TextField
            sx={{ mt: 3 }}
            value={approverValue[params.row.productId] || ""}
            onChange={(e) => handleApproverChange(e, params.row.productId)}
            onBlur={() => handleApproverBlur(params.row.productId)} // Update and exit edit mode on blur
            variant="standard"
            autoFocus
          />
        ) : (
          <Typography
            sx={{ mt: 3 }}
            onClick={() =>
              handleApproverClick(
                params.row.productId,
                params.row.productDetails.approvalID
              )
            }
          >
            {params.row.productDetails.approvalID || "Click to edit"}
          </Typography>
        ),
    },
    {
      field: "actions",
      headerClassName: "super-app-theme--header",
      headerName: allTranslations?.actions?.[userLang] ?? "ACTIONS",
      headerAlign: "center",
      sortable: false,
      flex: 1,
      renderCell: (params) =>
        !addedItems[params.row.productId] ? (
          <Button
            variant="outlined"
            disableElevation
            size="small"
            sx={{
              textTransform: "capitalize",
              fontSize: 10,
              bgcolor: theme.palette.icons.bgDark,
              color: theme.palette.text.white,
              border: `1px solid ${theme.palette.icons.bgDark}`,
              ":hover": {
                border: `1px solid ${theme.palette.primary.border}`,
                bgcolor: theme.palette.background.default,
                color: theme.palette.text.thin,
              },
            }}
            onClick={() => handleAddtoCart(params.row)}
          >
            {allTranslations?.add_to_cart?.[userLang] ?? "Add to Cart"}
          </Button>
        ) : (
          <Typography
            sx={{ textTransform: "capitalize", fontSize: 13, mt: 3.35 }}
          >
            {allTranslations?.added_to_cart?.[userLang] ?? "Added to Cart"}
          </Typography>
        ),
    },
  ];

  // if (dataLoading) {
  //   return (
  //     <Box
  //       sx={{
  //         // bgcolor: "background.default",
  //         padding: "15px",
  //         margin: 6,
  //         borderRadius: 3,
  //         px: 3,
  //       }}
  //     >
  //       <CircularProgress />
  //     </Box>
  //   );
  // }
  return (
    <>
      {!initial && finalData.length !== 0 && (
        <Box
          sx={(theme) => ({
            // height: 350,
            width: "100%",
            mt: 2,
            "& .super-app-theme--header": {
              backgroundColor: theme.palette.dataGrid.headerBg,
            },
          })}
        >
          <DataGrid
            rowHeight={80}
            rows={rows}
            autoHeight
            loading={isLoading}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 5,
                },
              },
            }}
            pageSizeOptions={[5]}
            disableRowSelectionOnClick
            sx={{
              bgcolor: "background.secondary",
            }}
          />
        </Box>
      )}
      <TableContainer
        component={Paper}
        m={0}
        sx={{
          borderTopLeftRadius: "0px",
          borderTopRightRadius: "0px",
        }}
      >
        <Table
          sx={{ minWidth: 650, bgcolor: "background.secondary", margin: 0 }}
          aria-label="simple table"
        >
          <TableBody m={0}>
            {initial ? (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  <Typography variant="h6" color="textSecondary">
                    {/* {allTranslations?.no_products_are_added?.[userLang] ??
                      "No products are added"} */}
                    {allTranslations?.search_for_products?.[userLang] ??
                      "Search for products"}
                  </Typography>
                </TableCell>
              </TableRow>
            ) : (
              finalData.length === 0 && (
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    <Typography variant="h6" color="textSecondary">
                      {allTranslations?.no_products_found?.[userLang] ??
                        "No products found"}
                    </Typography>
                  </TableCell>
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default ProductsTable;

import { useTheme } from "@emotion/react";
import { Box, Container, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import MiniMenu from "../components/common/miniMenu/MiniMenu";
import BreadCrumbs from "../components/common/breadCrumbs/BreadCrumbs";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Staticstics from "../components/admin/Staticstics";
import { getFeedbackCount } from "../apiCalls/allApiCalls";

const Admin = () => {
  const { user: profile } = useSelector((state) => state.users);
  const { loading: allTranslationsLoading, allTranslations } = useSelector(
    (state) => state.translations
  );
  const { translations } = useSelector((state) => state.translations);
  const { language: userLang } = useSelector((state) => state.userLanguage);
  const theme = useTheme();
  const navigate = useNavigate();
  const [feedbackData, setFeedbackData] = useState();
  const tileStyling =
    theme.palette.mode === "dark"
      ? {
          border: `2px solid ${theme.palette.background.default}`,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "8px",
          color: "#858585",
          bgcolor: theme.palette.background.default,
          cursor: "pointer",
          ":hover": tileHoverStyling,
        }
      : {
          border: `1px solid ${theme.palette.primary.grayShade}`,
          // filter: `drop-shadow(0px 2px 4px 0px ${theme.palette.primary.dropShadow})`,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "8px",
          color: "#858585",
          bgcolor: theme.palette.background.default,
          cursor: "pointer",
          "&:hover": {
            transform: "scale(1.02)",
          },
          boxShadow: `rgba(0, 0, 0, 0.16) 0px 2px 4px`,
        };
  const tileHoverStyling =
    theme.palette.mode === "dark"
      ? {
          border: "1.5px solid #0A7743",
          "-webkit-box-shadow": "0px 0px 36px 1px rgba(19,221,123,0.41)",
          "-moz-box-shadow": "0px 0px 36px 1px rgba(19,221,123,0.41)",
          "box-shadow": "0px 0px 36px 1px rgba(19,221,123,0.41)",
          color: "#b9b9b9",
        }
      : {
          border: "1.5px solid #0A7743",
          "-webkit-box-shadow": "0px 0px 36px 1px rgba(19,221,123,0.41)",
          "-moz-box-shadow": "0px 0px 36px 1px rgba(19,221,123,0.41)",
          "box-shadow": "0px 0px 36px 1px rgba(19,221,123,0.41)",
          color: "#b9b9b9",
        };

  const getFeedbackData = async () => {
    try {
      const response = await getFeedbackCount();

      setFeedbackData(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    getFeedbackData();
  }, []);
  console.log(feedbackData, "feedback");

  return (
    <Box width={"95%"} m={"auto"} p={4}>
      {/* <MiniMenu /> */}
      {/* <Container
        sx={{
          
          bgcolor: "background.default",
          borderRadius: 5,
          minHeight: "80vh",
          minWidth: "100%",
          // m: "auto",
        }}
      > */}
      {/* <Box
          sx={{
            mb: 4,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <BreadCrumbs />
        </Box> */}
      <Grid container columnSpacing={3}>
        <Grid item xs={4} mt={3}>
          {/* 2 */}
          <Grid container spacing={2}>
            <Grid item xs={6}>
              {/* 3 */}
              <Grid
                item
                container
                spacing={2}
                flexDirection="column"
                direction={"column"}
              >
                {/* Adjusting height based on Grid size */}
                <Grid
                  item
                  sx={{
                    ...tileStyling,
                    height: "calc(100vh * 0.3)",
                    paddingX: 3,
                    textAlign: "center",
                  }}
                  onClick={() => navigate("/admin/api-exception-logs")}
                >
                  <Typography color={"text.thin"}>
                    {allTranslations?.api_logs?.[userLang] ?? "Api Logs"}
                  </Typography>
                </Grid>
                <Grid
                  item
                  sx={{
                    ...tileStyling,
                    height: "calc(100vh * 0.2)",
                    mt: 2,
                    paddingX: 3,
                    textAlign: "center",
                  }}
                  onClick={() => navigate("/admin/ui-exception-logs")}
                >
                  <Typography color={"text.thin"}>
                    {allTranslations?.ui_exception_logs?.[userLang] ??
                      "UI Exceptional Logs"}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container spacing={2} flexDirection="column">
                {/* Adjusting height based on Grid size */}
                <Grid
                  item
                  sx={{
                    ...tileStyling,
                    height: "calc(100vh * 0.2)",
                    ml: 2,
                    paddingX: 3,
                    textAlign: "center",
                  }}
                  onClick={() => navigate("/admin/feedback")}
                >
                  <Typography color={"text.thin"}>
                    {allTranslations?.feedback?.[userLang] ?? "Feedback"}
                  </Typography>
                </Grid>
                <Grid
                  item
                  // flex={5}
                  sx={{
                    ...tileStyling,
                    height: "calc(100vh * 0.3)",
                    ml: 2,
                    mt: 2,
                    paddingX: 3,
                    textAlign: "center",
                  }}
                  onClick={() => navigate("/admin/language-translations")}
                >
                  <Typography color={"text.thin"}>
                    {allTranslations?.language_translations?.[userLang] ??
                      "Language Translations"}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                ...tileStyling,
                height: "calc(100vh * 0.17)",
                mt: 2,
                paddingX: 3,
                textAlign: "center",
                cursor: "not-allowed",
              }}
            >
              <Typography color={"text.thin"}>
                {allTranslations?.api_logs?.[userLang] ?? "Api Logs"}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={8}>
          <Staticstics feedbackData={feedbackData} />
        </Grid>
      </Grid>
      {/* </Container> */}
    </Box>
  );
};

export default Admin;

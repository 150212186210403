import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { useTheme } from "@emotion/react";
import ProductsTable from "../components/common/productsTable/ProductsTable";
import {
  getUserDetailsByUid,
  getUserProductDetails,
} from "../apiCalls/allApiCalls";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import BreadCrumbs from "../components/common/breadCrumbs/BreadCrumbs";
import Comments from "../components/newRequest/Comments";
import { Close } from "@mui/icons-material";
import OrgChart from "../components/common/orgChart/OrgChart";
import { toast } from "react-toastify";
import MiniMenu from "../components/common/miniMenu/MiniMenu";
import { tileShadows } from "./Root";
import NewOrgChart from "../components/common/orgChart/NewOrgChart";

const ReferenceUserLatest = () => {
  const { loading: profileLoading, user: profile } = useSelector(
    (state) => state.users
  );
  const { loading: allTranslationsLoading, allTranslations } = useSelector(
    (state) => state.translations
  );
  const { loading: userLangLoading, language: userLang } = useSelector(
    (state) => state.userLanguage
  );
  const navigate = useNavigate();
  const { state } = useLocation();
  const [activeSection, setActiveSection] = useState(0);
  const theme = useTheme();
  const [platform, setPlatform] = useState("");
  const [system, setSystem] = useState("");
  const [search, setSearch] = useState("");
  const [role, setRole] = useState("");
  const [addedItems, setAddedItems] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  // const [users, setUsers] = useState([]);
  const [comments, setComments] = useState("");
  const [initial, setInitial] = useState(true);

  //Attachments
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [resumeError, setResumeError] = useState("");
  const [fileNames, setFileNames] = useState([]);
  const [fileList, setFileList] = useState(null);

  //CART
  const [cart, setCart] = useState([]);

  //Drawer
  const [isActive, setIsActive] = useState(false);
  // State for user filters
  const [userName, setUserName] = useState("");
  const [userId, setUserId] = useState("");
  const [initialUserID, setInitialUserID] = useState();
  const [emailId, setEmailId] = useState("");
  const [employmentType, setEmploymentType] = useState("");
  const [dates, setDates] = useState();
  // State for filtered options
  // State for filtered options
  const [userNames, setUserNames] = useState([]);
  const [userIds, setUserIds] = useState([]);
  const [emailIds, setEmailIds] = useState([]);
  const [employmentTypes, setEmploymentTypes] = useState([]);
  const [userProductsLoading, setUserProductsLoading] = useState(true);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [filterLoading, setFilterLoading] = useState(true);
  const [selectedUserDetails, setSelectedUserDetails] = useState("");
  const [userRole, setUserRole] = useState("");
  const [submitLoading, setSubmitLoading] = useState(false);
  const [selectedUserProducts, setSelectedUserProducts] = useState([]);
  // console.log(selectedUserDetails, "selectedUserDetails");
  const [showMoreActive, setShowMoreActive] = useState(false);
  const {
    loading: allUsersLoading,
    allUsers,
    user,
  } = useSelector((state) => state.users);

  const gridItemStyling = {
    "&.MuiGrid-root.MuiGrid-item": {
      px: 0,
    },
  };
  // useEffect(() => {
  //   const uid = state?.uid;
  //   console.log(uid);
  //   setInitialUserID(uid);
  // }, [state]);

  useEffect(() => {
    if (initialUserID) {
      sessionStorage.setItem("orgChartUser", initialUserID);
      setInitialUserID(sessionStorage.getItem("orgChartUser"));
    }
    handleUserIdChange();
  }, [initialUserID]);

  useEffect(() => {
    handleUserIdChange();
  }, [allUsersLoading, allUsers]);

  useEffect(() => {
    console.log(
      "sessionStorage.getItem",
      sessionStorage.getItem("orgChartUser")
    );
    // setInitialUserID(state ? state?.uid : "");
    console.log("state", state?.uid);
    if (sessionStorage.getItem("orgChartUser") && !initialUserID) {
      // navigate("/reference-user", { state: initialUserID });
      setInitialUserID(sessionStorage.getItem("orgChartUser"));
    }
  }, []);

  // console.log(initialUserID);
  const [requestData, setRequestsData] = useState({
    requestorId: "U-76e2a7",
    requestType: "SAP",
    requestData: { cart },
  });

  async function fetchUserProducts(uid) {
    // setIsLoading(true);
    try {
      const response = await getUserProductDetails(uid);
      // console.log("fetchUserProducts", response);
      if (response) {
        setUserProductsLoading(false);
        setSelectedUserProducts(response);
        setIsLoading(false);
      }
    } catch (error) {
      toast.error(
        <div>
          Failed to fetch products
          <a
            href="mailto:ganeshg@aapmor.com"
            style={{ color: "blue", textDecoration: "underline" }}
          >
            contact support
          </a>
          .
        </div>
      );
    }
  }

  // console.log(userIds);
  const handleChangePlatform = (event) => {
    setPlatform(event.target.value);
    setInitial(false);
  };

  const handleClearAll = () => {
    setCart([]);
    setComments("");
    setAddedItems({});
    setFileNames([]);
    setSelectedFiles([]);
  };

  const handleChangeSystem = (event) => {
    setSystem(event.target.value);
    setInitial(false);
  };

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
    setInitial(false);
  };

  const handleRoleChange = (event) => {
    setRole(event.target.value);
    setInitial(false);
  };

  const handleClearFilters = () => {
    setSearch("");
    setPlatform("");
    setSystem("");
    setRole("");
  };

  async function fetchUserById(uid) {
    try {
      // setIsLoading(true);
      const response = allUsers[uid];
      // console.log(uid, response);
      // console.log(uid, user);
      // console.log(response, "userdeets");
      setSelectedUserDetails(response);
      setUserName(response.FullName);
      setUserId(response.Uid);
      setEmailId(response.Email);
      setEmploymentType(response.Role);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("Failed to fetch user details:", error);
    }
  }
  // async function fetchUserById(uid) {
  //   try {
  //     setIsLoading(true);
  //     const response = await getUserDetailsByUid(uid);
  //     if (response?.status === 200 && response.data) {
  //       // console.log(response.data, "userdeets");
  //       setSelectedUserDetails(response.data);
  //       setUserName(response.data.FullName);
  //       setUserId(response.data.Uid);
  //       setEmailId(response.data.Email);
  //       setEmploymentType(response.data.Role);
  //       setIsLoading(false);
  //     }
  //   } catch (error) {
  //     setIsLoading(false);
  //     console.error("Failed to fetch user details:", error);
  //   }
  // }

  const handleUserIdChange = () => {
    setUserProductsLoading(true);
    if (sessionStorage.getItem("orgChartUser")) {
      setInitial(false);
      fetchUserById(sessionStorage.getItem("orgChartUser"));
      // setUserId(initialUserID);
      fetchUserProducts(sessionStorage.getItem("orgChartUser"));
      // console.log("prods", products);
      // setSelectedUserProducts(products);
    } else {
      setSelectedUserProducts("");
      setUserId("");
      setInitial(false);
      setFilteredProducts("");
    }
  };

  const handleUpload = () => {
    if (selectedFiles) {
      console.log("selectedFiles:", selectedFiles);
      // setFileNames([...fileNames, selectedFiles.name]);
      // setFileConfirmed(true);
      setResumeError(false);
    } else {
      alert("Please select a file");
    }
  };

  const handleFileChange = (event) => {
    const files = event.target.files;
    const newFileNames = [...files].map((f) => f.name);
    const formData = new FormData();
    [...files].forEach((item) => {
      formData.append("file", item);
    });
    setSelectedFiles([...files]);
    setFileNames([...fileNames, ...newFileNames]);
    if (!fileList) {
      return;
    }
  };

  const filterFiles = (fName) => {
    const updatedFiles = fileNames.filter((eachFile) => eachFile !== fName);
    const updatedSelectedFiles = selectedFiles.filter(
      (eachFile) => eachFile.name !== fName
    );
    setFileNames(updatedFiles);
    setSelectedFiles(updatedSelectedFiles);
    console.log("updatedFiles:", updatedFiles);
  };

  // // Filtering the products by Filters
  const hasFilters = platform || system || search || role || userId;

  function filterProducts(productsList) {
    // console.log("from filters", productsList)
    setFilterLoading(true);
    const prods = hasFilters
      ? productsList.filter(
          (product) =>
            (!platform || product.productDetails.platform.includes(platform)) &&
            (!system || product.productDetails.system.includes(system)) &&
            (!search ||
              product.productDetails.productName
                .toLowerCase()
                .includes(search.toLowerCase())) &&
            (!role ||
              product.productDetails.approver
                .toLowerCase()
                .includes(role.toLowerCase()))
        )
      : [];
    // console.log(prods);
    setFilteredProducts(prods);
    setFilterLoading(false);
    // console.log("filtered", filterLoading, prods);
  }

  // updates products list on filter change
  useEffect(() => {
    if (selectedUserProducts && selectedUserProducts.length > 0) {
      // console.log("userProducts", selectedUserProducts);
      filterProducts(selectedUserProducts);
    }
  }, [selectedUserProducts, hasFilters, search, system, platform, role]);

  // useEffect(() => {
  //   const handleScroll = () => {
  //     const scrollPosition = window.scrollY + window.innerHeight / 2;

  //     const section0Offset = document.getElementById("section-0").offsetTop;
  //     const section1Offset = document.getElementById("section-1").offsetTop;
  //     const section2Offset = document.getElementById("section-2").offsetTop;

  //     if (scrollPosition >= section0Offset && scrollPosition < section1Offset) {
  //       setActiveSection(0);
  //     } else if (
  //       scrollPosition >= section1Offset &&
  //       scrollPosition < section2Offset
  //     ) {
  //       setActiveSection(1);
  //     } else if (scrollPosition >= section2Offset) {
  //       setActiveSection(2);
  //     }
  //   };

  //   window.addEventListener("scroll", handleScroll);

  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);
  // Example organization structure

  // if (isLoading) {
  //   return (
  //     <Box
  //       sx={{
  //         // bgcolor: "background.default",
  //         padding: "15px",
  //         margin: 6,
  //         borderRadius: 3,
  //         px: 3,
  //       }}
  //     >
  //       <CircularProgress />
  //     </Box>
  //   );
  // }

  return (
    <Box
      sx={{
        bgcolor: "background.default",
        padding: "15px",
        margin: 6,
        borderRadius: 3,
        minWidth: "90vw",
        minHeight: "100vh",
        px: 3,
      }}
    >
      <MiniMenu
        cart={cart}
        setCart={setCart}
        addedItems={addedItems}
        setAddedItems={setAddedItems}
      />
      <Box
      // sx={{
      //   position: "sticky",
      //   top: 63,
      //   padding: "10px 16px",
      // }}
      >
        {/* <Stack
            direction={"row"}
            spacing={3}
            sx={{
              justifyContent: "space-between",
            }}
          >
            <Box
              height={5}
              width={400}
              bgcolor={
                activeSection === 0
                  ? theme.palette.secondary.light
                  : "rgb(211, 211, 211)"
              }
            ></Box>

            <Box
              height={5}
              width={400}
              bgcolor={
                activeSection === 1
                  ? theme.palette.secondary.light
                  : "rgb(211, 211, 211)"
              }
            ></Box>

            <Box
              height={5}
              width={400}
              bgcolor={
                activeSection === 2
                  ? theme.palette.secondary.light
                  : "rgb(211, 211, 211)"
              }
            ></Box>
          </Stack> */}
        <BreadCrumbs />
      </Box>
      {!isLoading ? (
        <Box
          sx={{
            paddingTop: 5,
            // minHeight: "100vh",
          }}
        >
          <Box
            id="section-0"
            sx={{
              // minHeight: 50, // Adjusted to be less than 100vh
              // bgcolor: "rgb(55, 55, 57)",
              color: "white",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={6}>
                {" "}
                <Typography
                  sx={{
                    color: theme.palette.text.bold,
                    fontWeight: 600,
                    fontSize: 18,
                    mb: 1,
                  }}
                >
                  {allTranslations?.org_chart?.[userLang] ?? "Org Chart"}
                </Typography>
                <NewOrgChart
                  initialUserID={initialUserID}
                  setInitialUserID={setInitialUserID}
                  showMoreActive={showMoreActive}
                />
              </Grid>
              <Grid item xs={6}>
                {" "}
                <Box sx={{ height: "90%" }}>
                  <Typography
                    sx={{
                      color: theme.palette.text.bold,
                      fontWeight: 600,
                      fontSize: 18,
                      mb: 1,
                    }}
                  >
                    {allTranslations?.user_details?.[userLang] ??
                      "User Details"}
                  </Typography>
                  <Box
                    sx={{
                      position: "relative",
                      height: showMoreActive ? 620 : 360,
                    }}
                  >
                    {/* First box */}
                    <Box
                      bgcolor={theme.palette.secondary.light}
                      sx={{
                        width: "100%",
                        height: 30,
                        position: "absolute",
                        // top: 50,
                        // left: 50,
                        zIndex: 1,
                        borderTopLeftRadius: "50px",
                        borderTopRightRadius: "50px",
                      }}
                    >
                      .
                    </Box>
                    {/* Second box */}
                    <Box
                      width={"100%"}
                      height={"100%"}
                      borderRadius={"28px"}
                      bgcolor={theme.palette.tiles.bg}
                      sx={{
                        position: "absolute",
                        top: 5,
                        zIndex: 2, // Higher z-index to overlap the first box
                        ...tileShadows,
                      }}
                    >
                      {/* skdmskd */}
                      <Stack p={5} spacing={5}>
                        {/* <Stack direction={"row"} gap={10}> */}
                        <Stack direction={"row"} gap={1}>
                          <Typography
                            sx={{
                              fontWeight: 600,
                              color: theme.palette.text.bold,
                            }}
                          >
                            Family Name :
                          </Typography>
                          <Typography color={theme.palette.text.light}>
                            {selectedUserDetails?.LastName}
                          </Typography>
                        </Stack>
                        <Stack direction={"row"} gap={1}>
                          <Typography
                            sx={{
                              fontWeight: 600,
                              color: theme.palette.text.bold,
                            }}
                          >
                            Given/ First Name :
                          </Typography>
                          <Typography color={theme.palette.text.light}>
                            {selectedUserDetails?.FirstName}
                          </Typography>
                        </Stack>
                        {/* </Stack> */}
                        <Stack direction={"row"} gap={1}>
                          <Typography
                            sx={{
                              fontWeight: 600,
                              color: theme.palette.text.bold,
                            }}
                          >
                            Email :
                          </Typography>
                          <Typography color={theme.palette.text.light}>
                            {selectedUserDetails?.Email}
                          </Typography>
                        </Stack>
                        <Stack direction={"row"} gap={1}>
                          <Typography
                            sx={{
                              fontWeight: 600,
                              color: theme.palette.text.bold,
                            }}
                          >
                            User ID :
                          </Typography>
                          <Typography color={theme.palette.text.light}>
                            {selectedUserDetails?.Uid}
                          </Typography>
                        </Stack>
                        <Stack
                          direction={"row"}
                          justifyContent={"space-between"}
                        >
                          <Stack direction={"row"} gap={1}>
                            <Typography
                              sx={{
                                fontWeight: 600,
                                color: theme.palette.text.bold,
                              }}
                            >
                              Type :
                            </Typography>
                            <Typography color={theme.palette.text.light}>
                              {selectedUserDetails?.EmployeeType}
                            </Typography>
                          </Stack>
                          {!showMoreActive && (
                            <Button
                              variant="contained"
                              sx={{
                                bgcolor: theme.palette.primary.main,
                                ":hover": {
                                  bgcolor: theme.palette.primary.main,
                                },
                                boxShadow: "none",
                              }}
                              onClick={() => {
                                setShowMoreActive(!showMoreActive);
                              }}
                            >
                              Show More
                            </Button>
                          )}
                        </Stack>
                        {showMoreActive && (
                          <Stack spacing={5}>
                            <Stack direction={"row"} gap={1}>
                              <Typography
                                sx={{
                                  fontWeight: 600,
                                  color: theme.palette.text.bold,
                                }}
                              >
                                Phone Number :
                              </Typography>
                              <Typography color={theme.palette.text.light}>
                                {selectedUserDetails?.Contact || "N/A"}
                              </Typography>
                            </Stack>
                            <Stack direction={"row"} gap={1}>
                              <Typography
                                sx={{
                                  fontWeight: 600,
                                  color: theme.palette.text.bold,
                                }}
                              >
                                SpaceName:
                              </Typography>
                              <Typography color={theme.palette.text.light}>
                                {selectedUserDetails?.SpaceName || "N/A"}
                              </Typography>
                            </Stack>
                            <Stack direction={"row"} gap={1}>
                              <Typography
                                sx={{
                                  fontWeight: 600,
                                  color: theme.palette.text.bold,
                                }}
                              >
                                Reporting :
                              </Typography>
                              <Typography color={theme.palette.text.light}>
                                {selectedUserDetails?.Reporting || "N/A"}
                              </Typography>
                            </Stack>
                            <Stack
                              direction={"row"}
                              justifyContent={"space-between"}
                            >
                              <Stack direction={"row"} gap={1}>
                                <Typography
                                  sx={{
                                    fontWeight: 600,
                                    color: theme.palette.text.bold,
                                  }}
                                >
                                  Manager :
                                </Typography>
                                <Typography color={theme.palette.text.light}>
                                  {selectedUserDetails?.Manager || "N/A"}
                                </Typography>
                              </Stack>
                              {showMoreActive && (
                                <Button
                                  variant="contained"
                                  onClick={() => {
                                    setShowMoreActive(false);
                                  }}
                                  sx={{
                                    bgcolor: theme.palette.primary.main,
                                    ":hover": {
                                      bgcolor: theme.palette.primary.main,
                                    },
                                  }}
                                >
                                  Show Less
                                </Button>
                              )}
                            </Stack>
                          </Stack>
                        )}
                      </Stack>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>

          <>
            <Box
              id="section-1"
              sx={{
                minHeight: "35vh", // Adjust this as needed
                // border: "1px solid  ",
                // display: "flex",
                // alignItems: "center",
                // justifyContent: "center",
                // color: "white",
                // fontSize: 24,
                mt: 5,
              }}
            >
              <Typography
                sx={{
                  color: theme.palette.text.bold,
                  fontWeight: 600,
                  fontSize: 18,
                  mb: 1,
                }}
              >
                Product Details :
              </Typography>

              <ProductsTable
                data={filteredProducts ? filteredProducts : []}
                initial={initial}
                setCart={setCart}
                cart={cart}
                addedItems={addedItems}
                setAddedItems={setAddedItems}
                setDates={setDates}
                setFilteredProducts={setFilteredProducts}
                isLoading={isLoading}
              />
            </Box>
          </>

          <Box
            id="section-2"
            mt={3}
            sx={
              {
                // minHeight: "70vh", // Adjust this as needed
                // bgcolor: "rgb(55, 55, 57)",
                // display: "flex",
                // alignItems: "center",
                // justifyContent: "center",
                // color: "white",
                // fontSize: 24,
              }
            }
          >
            <Typography
              sx={{
                color: theme.palette.text.bold,
                fontWeight: 600,
                fontSize: 18,
                mb: 1,
                mt: 2,
              }}
            >
              Comments{" "}
              <span
                style={{
                  color: "tomato",
                }}
              >
                *
              </span>{" "}
              & Attachments :
            </Typography>
            <Comments
              comments={comments}
              setComments={setComments}
              handleFileChange={handleFileChange}
              fileNames={fileNames}
              filterFiles={filterFiles}
              Close={Close}
              resumeError={resumeError}
              setCart={setCart}
              setAddedItems={setAddedItems}
              setFileNames={setFileNames}
              addedItems={addedItems}
              setIsActive={setIsActive}
              isActive={isActive}
              cart={cart}
              selectedFiles={selectedFiles}
              handleClearAll={handleClearAll}
              profile={profile}
              requestType="Reference User"
              submitLoading={submitLoading}
              setSubmitLoading={setSubmitLoading}
            />
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            // bgcolor: "background.default",
            padding: "15px",
            margin: 6,
            borderRadius: 3,
            px: 3,
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
};

export default ReferenceUserLatest;

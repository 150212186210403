import "./App.css";
import * as React from "react";
import * as ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Root from "./pages/Root";
import ErrorPage from "./pages/ErrorPage";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import NewRequest from "./pages/NewRequest";
import RequestStatus from "./pages/RequestStatus";
import NewChangeRequest from "./pages/NewChangeRequest";
import RequestDetails from "./pages/RequestDetails";
import PeerRequest from "./pages/PeerRequest";
import Recertifications from "./pages/Recertifications";
import ElevatedRequest from "./pages/ElevatedRequest";
import ReferenceUser from "./pages/ReferenceUser";
import Approver from "./pages/Approver";
import ApproveRequestDetails from "./pages/ApproveRequestDetails";
import Compliance from "./pages/Compliance";
import Deprovisioning from "./pages/Deprovisioning";
import UnlockUser from "./pages/UnlockUser";
import ComplianceDetails from "./pages/ComplianceDetails";
import Delegation from "./pages/Delegation";
import NewDelegation from "./pages/NewDelegation";
import AllRequests from "./pages/AllRequests";
import ReferenceUserLatest from "./pages/IdentityViewer";
import RaiseDelegation from "./pages/RaiseDelegation";

import Settings from "./pages/Settings";
import Admin from "./pages/Admin";
import UserFeedback from "./components/admin/UserFeedback";
import ApiExceptionLogs from "./components/admin/ApiExceptionLogs";
import ErrorBoundaryComponent from "./helpers/ErrorBoundaryComponent ";
import UIExceptionLogs from "./components/admin/UIExceptionLogs";
import LanguageTranslations from "./components/admin/LanguageTranslations";
import ForwardRequest from "./pages/ForwardRequest";
import BulkUpload from "./pages/BulkUpload";

function App() {
  const router = createBrowserRouter([
    // public routes
    {
      path: "/login",
      element: <Login />,
    },
    // private routes
    {
      path: "/",
      element: (
        <ErrorBoundaryComponent>
          <Root />
        </ErrorBoundaryComponent>
      ), // contains website layout
      errorElement: <ErrorPage />,
      children: [
        {
          index: true,
          element: <Dashboard />,
        },
        // {
        //   path: "new-request",
        //   element: <NewRequest />,
        // },
        {
          path: "new-request/:id?",
          element: <NewChangeRequest />,
        },
        {
          path: "request-status",
          element: <RequestStatus />,
        },
        {
          path: "peer-request/:id?",
          element: <PeerRequest pageName={"Peer Details"} />,
        },
        {
          path: "elevated-request/:id?",
          element: <ElevatedRequest pageName={"Elevated Access"} />,
        },
        {
          path: "request-details/:id",
          element: <RequestDetails />,
        },
        {
          path: "recertifications",
          element: <Recertifications />,
        },
        {
          path: "reference-user/:id?",
          element: <ReferenceUserLatest pageName={"Reference User"} />,
        },
        {
          path: "approver",
          element: (
            // <ErrorBoundaryComponent>
            <Approver path="approver" />
            // </ErrorBoundaryComponent>
          ),
        },
        {
          path: "approver/:reqId",
          element: <ApproveRequestDetails path="approver" />,
        },
        {
          path: "compliance",
          element: <Compliance path="compliance" />,
        },
        {
          path: "compliance/:reqId",
          element: <ComplianceDetails />,
        },
        {
          path: "deprovisioning",
          element: <Deprovisioning />,
        },
        {
          path: "/approver/delegation",
          element: <Delegation />,
        },
        // {
        //   path: "/:type/delegation/new-delegation",
        //   element: <NewDelegation />,
        // },
        {
          path: "/:type/delegation/new-delegation",
          element: <RaiseDelegation />,
        },
        {
          path: "/compliance/delegation",
          element: <Delegation />,
        },
        {
          path: "/approver/requests",
          element: <AllRequests />,
        },
        {
          path: "/compliance/requests",
          element: <AllRequests />,
        },
        // {
        //   path: "bulk-upload",
        //   element: <NewChangeRequest />,
        // },
        {
          path: "unlock-user",
          element: <UnlockUser />,
        },
        {
          path: "admin",
          element: <Admin />,
        },
        {
          path: "admin/feedback",
          element: <UserFeedback />,
        },
        {
          path: "admin/language-translations",
          element: <LanguageTranslations />,
        },
        // {
        //   path: "admin",
        //   element: <Admin />,
        // },
        {
          path: "/admin/api-exception-logs",
          element: <ApiExceptionLogs />,
        },
        {
          path: "/admin/ui-exception-logs",
          element: <UIExceptionLogs />,
        },
        {
          path: "/forward-request",
          element: <ForwardRequest />,
        },
        {
          path: "/bulk-upload",
          element: <BulkUpload />,
        },

        // {
        //   path: "tiles/:id",
        //   element: <Tiles />,
        // },
      ],
    },
  ]);

  return <RouterProvider router={router} />;
}

export default App;

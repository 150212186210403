import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Box,
  Stack,
  Avatar,
  Divider,
  IconButton,
} from "@mui/material";
import { Handle, Position } from "react-flow-renderer";
import { useTheme } from "@emotion/react";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";

const CustomNode = ({ data }) => {
  const theme = useTheme();

  const handleClick = (e) => {
    console.log("Custom node clicked!", data);
  };

  useEffect(() => {
    console.log("use data", data);
  }, [data]);

  return (
    <Box
      minWidth={180}
      height={data.type === "static" ? 40 : 90}
      // bgcolor={"pink"}
      sx={{
        // px: 2,
        cursor: "pointer",
        // transition: "transform 0.3s ease-in-out, background-color 0.3s",
        // ":hover": {
        //   transform: "scale(1.03)",
        // },
        pb: 0,
      }}
    >
      {/* Left Handle */}
      {data.type !== "static" && (
        <Handle type="target" position={Position.Left} style={{ top: "30%" }} />
      )}
      {/* Bottom Handle */}
      {/* {data.type !== "static" ? (
        <Handle
          type="source"
          position={Position.Bottom}
          style={{
            left: "20%",
            display:
              data.subordinates && data.subordinates.length > 0
                ? "flex"
                : "none",
          }}
        />
      ) : (
        <Handle
          type="source"
          position={Position.Bottom}
          style={{ left: "20%" }}
        />
      )} */}
      <Handle
        type="source"
        position={Position.Bottom}
        style={{
          left: "20%",
          // display:
          //   data.type !== "static" &&
          //   data.subordinates &&
          //   data.subordinates.length > 0
          //     ? "flex"
          //     : "none",
        }}
      />

      {/* conditionally render node style */}
      {data.type === "static" ? (
        // 'ONE LEVEL UP' NODE STYLE
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          width={"100%"}
          height={"100%"}
          border={`1px solid ${theme.palette.secondary.light}`}
          px={2}
          borderRadius={2}
          bgcolor={theme.palette.icons.colorActive}
          onClick={handleClick} // Navigate up one level
        >
          <Typography
            variant="h6"
            align="center"
            sx={{ color: theme.palette.text.light, fontSize: 13 }}
          >
            {data.label}
          </Typography>
          {data.type === "static" && (
            <ArrowUpwardIcon
              sx={{ color: theme.palette.secondary.light, fontSize: 22 }}
            />
          )}
        </Stack>
      ) : (
        // 'USER DETAILS' NODE STYLE
        <Box
          width={270}
          height={"65%"}
          py={1.3}
          borderRadius={2}
          bgcolor={theme.palette.secondary.light}
        >
          <Stack direction={"row"} alignItems={"center"}>
            <Box height={40} width={40} ml={2} borderRadius={2}>
              <Avatar sx={{ bgcolor: theme.palette.primary.main }} />
            </Box>
            <Divider
              orientation="vertical"
              sx={{
                height: 40,
                mx: 1,
                width: 0.01,
                borderColor: theme.palette.primary.main,
              }}
            />
            <Stack direction={"column"}>
              <Typography
                sx={{
                  fontWeight: 600,
                  textDecoration: "underline",
                  color: theme.palette.icons.bgDark,
                  fontSize: "14px",
                }}
              >
                {data.userObj?.FirstName || data.currentUser}{" "}
                {/* Display current user's name */}
              </Typography>
              <Typography
                sx={{
                  color: theme.palette.text.thin,
                  fontSize: "13px",
                }}
              >
                {data.userObj?.JobTitle || data.currentUser}{" "}
                {/* Display current user's name */}
              </Typography>
            </Stack>
          </Stack>
        </Box>
      )}
      {data.type !== "static" && (
        <Typography
          variant="h6"
          align="center"
          sx={{
            color: theme.palette.text.light,
            fontSize: 14,
            textAlign: "left",
            mt: 1,
            ml: 1,
          }}
        >
          Direct Reports
        </Typography>
      )}
    </Box>
  );
};

export default CustomNode;

import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Paper,
  Stack,
  Typography,
  IconButton,
} from "@mui/material";
import { useTheme } from "@emotion/react";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { Handle, Position } from "react-flow-renderer";

const Subordinates = ({ data }) => {
  const theme = useTheme(); // Access the theme for styling
  const [expandedSubordinates, setExpandedSubordinates] = useState({}); // Track which subordinates are expanded
  // Helper function to find user by their ID
  const findUserById = (Uid) => data.hierarchy[Uid];

  // Toggle the expansion of subordinates
  const toggleSubordinate = (subordinateId) => {
    console.log("toggleSubordinate called", subordinateId)
    setExpandedSubordinates((prevState) => ({
      ...prevState,
      [subordinateId]: !prevState[subordinateId], // Toggle the expanded state
    }));
  };

  const handleClick = (e) => {
    console.log("Custom node clicked!", data);
  };

  if (data) {
    console.log("3", data);
  }

  return (
    <Stack>
      {/* Left Handle */}
      {data.subordinates.length > 0 && (
        <Handle type="target" position={Position.Left} style={{ top: "8%" }} />
      )}
      {data.subordinates && data.subordinates.length > 0 ? (
        data.subordinates.map((subordinate) => {
          const subordinateDetails = findUserById(subordinate); // Get details of the subordinate
          const hasSubordinates = subordinateDetails?.subordinates?.length > 0; // Check if the subordinate has their own subordinates
          const isExpanded = expandedSubordinates[subordinate]; // Check if this subordinate is expanded

          return (
            <Box key={subordinate} pl={2}>
              <Stack direction="row" alignItems="center" gap={1.5}>
                {/* Display subordinate's name, or fallback to their ID */}
                <Typography
                  // onClick={handleClick} // On click, set this subordinate as the current user
                  sx={{
                    color: theme.palette.text.thin,
                    cursor: "pointer",
                    transition: "color 0.3s, transform 0.3s",
                    textDecoration: "underline",
                    ":hover": {
                      transform: "scale(1.05)",
                    },
                    fontSize: "12.5px",
                  }}
                  id={subordinate}
                  // mt={2}
                >
                  {data.allUsers[subordinate]?.FirstName || subordinate}{" "}
                </Typography>
                {/* Show toggle button if this subordinate has more subordinates */}
                {/* {hasSubordinates && (
                  <IconButton
                    data-id="icon-button"
                    onClick={(e) => {
                      // e.stopPropagation();
                      console.log("isExpanded", isExpanded)
                      console.log("hasSubordinates", hasSubordinates)
                      toggleSubordinate(subordinate)
                    }} // Toggle visibility of subordinates
                    sx={{
                      transition: "color 0.3s, transform 0.3s",
                      ":hover": {
                        bgcolor: "transparent",
                        color: theme.palette.primary.light,
                        transform: "scale(1.2)",
                      },
                      p: 0,
                    }}
                    id="button"
                  >
                    {isExpanded ? (
                      <RemoveCircleOutlineIcon
                        sx={{
                          fontSize: "17px",
                          color: theme.palette.secondary.light,
                          p: 0,
                        }}
                      /> // Show collapse icon
                    ) : (
                      <AddCircleOutlineIcon
                        sx={{
                          fontSize: "17px",
                          color: theme.palette.secondary.light,
                          p: 0,
                        }}
                      /> // Show expand icon
                    )}
                  </IconButton>
                )} */}
              </Stack>
              {/* Render subordinates if expanded */}
              {/* {isExpanded && hasSubordinates && (
                <Subordinates subordinates={subordinateDetails.subordinates} />
              )} */}
            </Box>
          );
        })
      ) : (
        <Typography
          sx={{
            color: theme.palette.text.thin,
            fontSize: "13px",
          }}
        >
          No subordinates to display
        </Typography>
      )}
    </Stack>
  );
};

export default Subordinates;

import {
  Avatar,
  Box,
  Divider,
  IconButton,
  Menu,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import LogoutIcon from "@mui/icons-material/Logout";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useTheme } from "@emotion/react";

const Profile = ({ handleLogout, anchorElAvatar, handleCloseAvatarMenu }) => {
  const { loading: profileLoading, user: profile } = useSelector(
    (state) => state.users
  );
  const { loading: userLangLoading, language: userLang } = useSelector(
    (state) => state.userLanguage
  );
  const { loading: allTranslationsLoading, allTranslations } = useSelector(
    (state) => state.translations
  );
  const [fullNameCode, setFullNameCode] = useState("");
  const [roleCode, setRoleCode] = useState("");
  const [departmentCode, setDepartmentCode] = useState("");
  const theme = useTheme();

  useEffect(() => {
    if (allTranslations && allTranslations.length > 0 && profile) {
      const fn = profile?.FirstName?.replace(/ /g, "_");
      const ln = profile?.LastName?.replace(/ /g, "_");
      const code = `${fn?.toLowerCase()}_${ln?.toLowerCase()}`;
      const fullName = allTranslations[code];

      if (fullName) {
        setFullNameCode(fullName[userLang]);
      }

      let key = profile?.JobTitle?.trim().replace(/ /g, "_").toLowerCase();
      const role = allTranslations[key];
      if (role) {
        setRoleCode(role[userLang]);
      }

      key = profile?.SpaceName?.trim().replace(/ /g, "_").toLowerCase();
      const dep = allTranslations[key];
      if (dep) {
        setDepartmentCode(dep[userLang]);
      }
    }
  }, [allTranslations, profile, userLang]);

  return (
    <Menu
      id="menu-avatar"
      anchorEl={anchorElAvatar}
      open={Boolean(anchorElAvatar)}
      onClose={handleCloseAvatarMenu}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      slotProps={{
        paper: {
          sx: {
            maxHeight: 350,
          },
        },
      }}
    >
      <Box
        sx={{
          px: 1.5,
          py: 1,
          // pt: 2,
          // pb: 5,
          // height: 330,
          width: 280,
          borderRadius: 5,
          // boxShadow: 3,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          // bgcolor: "background.paper",
          // bgcolor: "pink",
        }}
      >
        <IconButton
          sx={{ position: "absolute", top: 10, left: 3 }}
          onClick={handleLogout}
        >
          <Tooltip title={allTranslations?.logout?.[userLang] ?? "Logout"}>
            <LogoutIcon sx={{ color: theme.palette.secondary.main }} />
          </Tooltip>
        </IconButton>
        <IconButton
          sx={{ position: "absolute", top: 10, right: 3 }}
          onClick={handleCloseAvatarMenu}
        >
          <Tooltip title={allTranslations?.close?.[userLang] ?? "Close"}>
            <CloseIcon sx={{ color: theme.palette.secondary.main }} />
          </Tooltip>
        </IconButton>

        <Box
          sx={{
            position: "relative",
            mb: 2,
          }}
        >
          <Box
            sx={{
              border: `5px solid ${theme.palette.primary.main}`,
              borderRadius: "50%",
            }}
          >
            <Avatar
              // src="https://via.placeholder.com/100"
              alt="Trevor Santiago"
              sx={{
                width: 64,
                height: 64,
                bgcolor: theme.palette.secondary.light,
              }}
            />
          </Box>
          <IconButton
            sx={{
              position: "absolute",
              bottom: 0,
              right: 0,
              bgcolor: theme.palette.secondary.main,
              color: "black",
              width: 24,
              height: 24,
              p: 0.5,
              // ":hover": {
              //   bgcolor: "primary.main",
              // },
            }}
          >
            <EditIcon fontSize="small" sx={{ color: "white" }} />
          </IconButton>
        </Box>
        <Typography fontSize={16} component="div">
          {fullNameCode ? fullNameCode : `${profile?.FirstName} ${profile?.LastName}`}
        </Typography>
        <Typography fontSize={14} gutterBottom>
          {roleCode ? roleCode : profile?.JobTitle}
        </Typography>
        <Divider
          sx={{ width: "100%", my: 2, borderColor: theme.palette.divider }}
        />
        <Stack sx={{ textAlign: "left", width: "100%", px: 2, gap: 0 }}>
          <Typography sx={{ mt: 1, fontSize: 14 }}>
            {allTranslations?.user?.[userLang] ?? "User"} ID:{" "}
            <strong> {profile?.Uid}</strong>
          </Typography>
          <Typography
            sx={{
              mt: 1,
              fontSize: 14,
              // whiteSpace: "normal", // Allows wrapping to the next line
              // overflow: "visible", // Ensures overflow text is visible
              // maxWidth: "100%",
              // textWrap: "wrap", // Limits the width to the container
            }}
          >
            {allTranslations?.user?.[userLang] ?? "User"}{" "}
            {allTranslations?.email?.[userLang] ?? "email"} ID:{" "}
            <Tooltip title={profile?.Email ?? ""} arrow>
              <Typography
                sx={{
                  mt: 1,
                  fontSize: 14,
                  whiteSpace: "nowrap", // Keeps it on a single line
                  overflow: "hidden", // Prevents overflow
                  textOverflow: "ellipsis", // Adds ellipsis for very long text
                  maxWidth: "100%", // Ensures it respects the container's width
                  // cursor: "pointer", // Changes cursor to pointer to indicate hoverable
                }}
              >
                <strong>{profile?.Email}</strong>
              </Typography>
            </Tooltip>
          </Typography>
          <Typography sx={{ mt: 1, fontSize: 14 }}>
            {allTranslations?.department?.[userLang] ?? "Department"}:{" "}
            <strong>{departmentCode ? departmentCode : profile?.SpaceName}</strong>
          </Typography>
          {/* <Typography sx={{ mt: 1, fontSize: 14 }}>Access:</Typography> */}
          <Typography sx={{ mt: 1, fontSize: 14 }}>
            {allTranslations?.reporting_to?.[userLang] ?? "Reporting to"}:{" "}
            <strong>
              {profile?.manager ??
                allTranslations?.kartheek_chakrala?.[userLang] ??
                "Kartheek Chakrala"}
            </strong>
          </Typography>
        </Stack>
      </Box>
    </Menu>
  );
};

export default Profile;

import { Grid, Stack, Divider, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";

import { useTheme } from "@emotion/react";
import MiniMenu from "../components/common/miniMenu/MiniMenu";
import Masonry from "../components/dashboard/Masonry";
import SeatedMan from "../assets/seated-man-showing-something.png";
import ArticleIcon from "@mui/icons-material/Article";
import RequestTiles from "../components/dashboard/RequestTiles";
import { getRequestDetails } from "../apiCalls/allApiCalls.js";
import { useSelector } from "react-redux";
import { heroGradient } from "./Root.jsx";

const Dashboard = () => {
  const theme = useTheme();

  const [cart] = useState([]);
  const [, setLoading] = useState(false);
  const [requestsData, setRequestsData] = useState([]);
  const { translations } = useSelector((state) => state.translations);
  const { loading: allTranslationsLoading, allTranslations } = useSelector(
    (state) => state.translations
  );
  const { language: userLang } = useSelector((state) => state.userLanguage);
  const { user: profile } = useSelector((state) => state.users);

  useEffect(() => {
    if (!profile) return;

    const fetchAllData = async () => {
      try {
        const query = `userId=${profile.Uid}`;
        const requestResponse = await getRequestDetails(query);

        if (requestResponse?.status === 200) {
          const requestsData = requestResponse.data;

          // Sort requestsData by date in descending order
          requestsData.sort(
            (a, b) => new Date(a.updatedAt) - new Date(b.updatedAt)
          );

          setRequestsData(requestsData);
          setLoading(false);
        } else {
          throw new Error("Failed to fetch request details");
        }
      } catch (error) {
        console.error("Failed to fetch data:", error);

        setLoading(false);
      }
    };

    fetchAllData();
  }, [profile]);

  // Count of requests with status "in progress"
  const inProgressCount = requestsData.filter(
    (request) => request.status === "In Progress"
  ).length;

  // console.log("Requests in progress count:", inProgressCount);
  return (
    <Stack
      direction={"row"}
      justifyContent={"center"}
      gap={2}
      // bgcolor={"pink"}
      maxHeight={"85vh"}
      pb={3}
    >
      <MiniMenu length={cart.length > 0 ? cart.length : 0} />
      {/* LEFT SECTION */}
      <Masonry />
      <Divider
        orientation={"vertical"}
        variant="fullWidth"
        sx={{
          borderColor: "#C9C9C9",
          width: "0.1px",
          // borderRadius: 2,
          height: "80vh",
        }}
      />
      {/*  RIGHT SECTION */}
      <Grid container item xs={7} gap={"10px"} alignContent={"flex-start"}>
        {/* top */}
        <Grid
          item
          xs={12}
          sx={
            ({
              // border: "2px solid #000",
              "&.MuiGrid-root.MuiGrid-item": {
                px: 0,
                pt: 0.5,
              },
            },
            {
              background: `linear-gradient(to right, ${theme.palette.success.tileGradient1}, ${theme.palette.success.tileGradient2})`,
              // ...heroGradient,
              color: theme.palette.text.primary,
              borderRadius: "8px",
              bgcolor: "white",
              border: `1px solid ${theme.palette.primary.border}`,
            })
          }
          height={"133px"}
          // style={{
          //   background: "linear-gradient(to right bottom, #276555, #0A7743)",
          // }}
        >
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            // bgcolor={"pink"}
            sx={{ px: 3, borderRadius: "8px" }}
          >
            <Stack justifyContent={"center"} mr={3}>
              <Typography
                sx={{ fontSize: "20px", color: theme.palette.text.light }}
              >
                {allTranslations?.requests_pending?.[userLang] ??
                  "Requests pending"}
              </Typography>
              <Stack direction={"row"} alignItems={"center"} gap={0.5}>
                <ArticleIcon
                  sx={{ fontSize: "35px", color: theme.palette.text.thin }}
                />
                <Typography
                  variant="h4"
                  fontWeight={600}
                  color={theme.palette.text.thin}
                >
                  {inProgressCount}
                </Typography>
              </Stack>
            </Stack>
            <Stack justifyContent={"center"}>
              <Typography
                sx={{
                  fontSize: "18px",
                  mb: 0.5,
                  width: "200px",
                  color: theme.palette.text.light,
                }}
              >
                Jira end user
                {allTranslations?.is_getting_expired_in?.[userLang] ??
                  " is getting expired in"}
              </Typography>
              <Typography
                variant="h4"
                fontWeight={600}
                color={theme.palette.text.thin}
              >
                22 {allTranslations?.days?.[userLang] ?? " days"}
              </Typography>
            </Stack>
            <img src={SeatedMan} width="200px" height="133px" />
          </Stack>
        </Grid>
        {/* 4 items */}
        <RequestTiles />
      </Grid>
    </Stack>
  );
};

export default Dashboard;

import React, { useEffect, useState } from "react";
import { Box, Grid, CircularProgress, Typography, Stack } from "@mui/material";
import "../components/common/tile/Tile.css";
import { useSelector } from "react-redux";
import { getRequestByApprover } from "../apiCalls/allApiCalls";
import RequestsTable from "../components/common/approver/RequestsTable";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@emotion/react";
import SeatedMan from "../assets/seated-man-showing-something.png";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";

function Approver() {
  const navigate = useNavigate();
  const theme = useTheme();
  const { user: profile } = useSelector((state) => state.users);
  const { loading: allTranslationsLoading, allTranslations } = useSelector(
    (state) => state.translations
  );
  const { translations } = useSelector((state) => state.translations);
  const { language: userLang } = useSelector((state) => state.userLanguage);
  const [requestsData, setRequestsData] = useState([]);
  const [requestCount, setRequestCount] = useState({
    countInProgress: 0,
    countApproved: 0,
  });
  const [delegationRequests, setDelegationRequests] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchAllData = async () => {
      try {
        const requestResponse = await getRequestByApprover(profile?.Uid);
        setLoading(true);
        if (requestResponse?.status === 200) {
          let requestsData = await requestResponse?.data.requests;
          setRequestCount(requestResponse?.data.count);
          setRequestsData(requestsData); // Set the filtered in-progress data
          setDelegationRequests(requestResponse?.data?.delegationRequests);
          setLoading(false);
        } else {
          throw new Error("Failed to fetch request details");
        }
      } catch (error) {
        console.error("Failed to fetch data:", error);
        // setError(error.message);
        setLoading(false);
      }
    };
    if (profile.Uid) {
      fetchAllData();
    }
  }, [profile]);

  if (loading) {
    return (
      <Box
        sx={{
          width: "100vw",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
        }}
      >
        <CircularProgress sx={{ color: theme.palette.text.primary }} />
      </Box>
    );
  }

  const tileHoverStyling =
    theme.palette.mode === "dark"
      ? {
          border: "1.5px solid #0A7743",
          "-webkit-box-shadow": "0px 0px 36px 1px rgba(19,221,123,0.41)",
          "-moz-box-shadow": "0px 0px 36px 1px rgba(19,221,123,0.41)",
          "box-shadow": "0px 0px 36px 1px rgba(19,221,123,0.41)",
          color: "#b9b9b9",
        }
      : {
          border: "1.5px solid #0A7743",
          "-webkit-box-shadow": "0px 0px 36px 1px rgba(19,221,123,0.41)",
          "-moz-box-shadow": "0px 0px 36px 1px rgba(19,221,123,0.41)",
          "box-shadow": "0px 0px 36px 1px rgba(19,221,123,0.41)",
          color: "#b9b9b9",
        };

  const tileStyling =
    theme.palette.mode === "dark"
      ? {
          border: `2px solid ${theme.palette.background.default}`,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "8px",
          color: "#858585",
          bgcolor: theme.palette.background.default,
          cursor: "pointer",
          ":hover": tileHoverStyling,
        }
      : {
          border: `1px solid ${theme.palette.primary.grayShade}`,
          // filter: `drop-shadow(0px 2px 4px 0px ${theme.palette.primary.dropShadow})`,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "8px",
          color: "#858585",
          bgcolor: theme.palette.background.default,
          cursor: "pointer",
          "&:hover": {
            transform: "scale(1.02)",
          },
          boxShadow: `rgba(0, 0, 0, 0.16) 0px 2px 4px`,
        };

  return (
    <Box
      sx={{
        padding: 3,
        minWidth: "90vw",
        margin: 1,
        borderRadius: 3,
        color: "white",
      }}
    >
      {/* 1 */}
      <Grid container columnSpacing={3}>
        <Grid item xs={5}>
          {/* 2 */}
          <Grid container spacing={2}>
            <Grid item xs={6}>
              {/* 3 */}
              <Grid
                item
                container
                spacing={2}
                flexDirection="column"
                direction={"column"}
              >
                {/* Adjusting height based on Grid size */}
                <Grid
                  item
                  sx={{
                    ...tileStyling,
                    height: "calc(100vh * 0.3)",
                    paddingX: 3,
                    textAlign: "center",
                  }}
                  onClick={() => navigate("/approver/requests")}
                >
                  <Typography color={"text.thin"}>
                    {allTranslations?.access_approver?.[userLang] ??
                      "Access Approver"}
                  </Typography>
                </Grid>
                <Grid
                  item
                  sx={{
                    ...tileStyling,
                    height: "calc(100vh * 0.2)",
                    mt: 2,
                    paddingX: 3,
                    textAlign: "center",
                  }}
                >
                  <Typography color={"text.thin"}>
                    {allTranslations?.user_to_role?.[userLang] ??
                      "User to Role"}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container spacing={2} flexDirection="column">
                {/* Adjusting height based on Grid size */}
                <Grid
                  item
                  sx={{
                    ...tileStyling,
                    height: "calc(100vh * 0.2)",
                    ml: 2,
                    paddingX: 3,
                    textAlign: "center",
                  }}
                  onClick={() => navigate("/approver/delegation")}
                >
                  <Typography color={"text.thin"}>
                    {allTranslations?.delegation?.[userLang] ?? "Delegation"}
                  </Typography>
                </Grid>
                <Grid
                  item
                  // flex={5}
                  sx={{
                    ...tileStyling,
                    height: "calc(100vh * 0.3)",
                    ml: 2,
                    mt: 2,
                    paddingX: 3,
                    textAlign: "center",
                  }}
                >
                  <Typography color={"text.thin"}>
                    {allTranslations?.user_to_critical?.[userLang] ??
                      "User to Critical"}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                ...tileStyling,
                height: "calc(100vh * 0.17)",
                mt: 2,
                paddingX: 3,
                textAlign: "center",
              }}
            >
              <Typography color={"text.thin"}>
                {allTranslations?.user_to_critical?.[userLang] ??
                  "Critical to User"}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={7}>
          <Box
            item
            sx={
              ({
                "&.MuiGrid-root.MuiGrid-item": {
                  px: 0,
                },
              },
              theme.palette.mode === "dark"
                ? {
                    background: `linear-gradient(to right bottom, ${theme.palette.success.tileGradient1}, ${theme.palette.success.tileGradient2})`,
                    color: "white",
                    borderRadius: "8px",
                    mt: -2,
                  }
                : {
                    background: `linear-gradient(to right, ${theme.palette.success.tileGradient1}, ${theme.palette.success.tileGradient2})`,
                    borderRadius: "8px",
                    mt: -2,
                  })
            }
            height={"133px"}
          >
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              sx={{
                px: 3,
                borderRadius: "8px",
                color: theme.palette.text.primary,
              }}
            >
              <Stack justifyContent={"center"}>
                <Typography sx={{ fontSize: "18px" }}>
                  {allTranslations?.requests_pending?.[userLang] ??
                    "Requests pending"}
                </Typography>
                <Stack direction={"row"} alignItems={"center"} gap={0.5}>
                  <AssignmentOutlinedIcon sx={{ fontSize: "35px" }} />
                  <Typography variant="h4" fontWeight={600}>
                    {/* {inProgressCount} */}
                    {requestCount.countInProgress + delegationRequests?.length}
                  </Typography>
                </Stack>
              </Stack>
              <Stack justifyContent={"center"}>
                <Typography sx={{ fontSize: "18px" }}>
                  {allTranslations?.approved?.[userLang] ?? "Approved"}{" "}
                  {allTranslations?.request?.[userLang] ?? " Requests"}
                </Typography>
                <Stack direction={"row"} alignItems={"center"} gap={0.5}>
                  <AssignmentOutlinedIcon sx={{ fontSize: "35px" }} />
                  <Typography variant="h4" fontWeight={600}>
                    {requestCount.countApproved}
                  </Typography>
                </Stack>
              </Stack>

              <img src={SeatedMan} width="200px" height="133px" />
            </Stack>
          </Box>
          <Box
            sx={{
              bgcolor: theme.palette.background.default,
              p: 3,
              borderRadius: 3,
              mt: 2,
            }}
          >
            <RequestsTable
              requestsData={[...requestsData, ...delegationRequests]}
              status={"In Progress"}
              path="approver"
            />
          </Box>
        </Grid>
      </Grid>
    </Box>

    
  );
}

export default Approver;

import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Rating from "@mui/material/Rating";
import { Stack } from "@mui/material";
import OutlinedInput from "@mui/material/OutlinedInput";
import { useNavigate } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { useSelector } from "react-redux";
import { raiseFeedback } from "../../apiCalls/allApiCalls";
import { toast } from "react-toastify";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

// Alert component for Snackbar
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function FeedbackForm(props) {
  const { loading: profileLoading, user: profile } = useSelector(
    (state) => state.users
  );
  const { loading: allTranslationsLoading, allTranslations } = useSelector(
    (state) => state.translations
  );
  const { loading: userLangLoading, language: userLang } = useSelector(
    (state) => state.userLanguage
  );
  const [isSubmitDisabled, setIsSubmitDisabled] = React.useState(false);
  const navigate = useNavigate();
  const [rating, setRating] = React.useState({
    overAllRating: 0,
    easyToFind: 0,
    easyToComplete: 0,
  });
  const [comment, setComment] = React.useState("");
  const [openSnackbar, setOpenSnackbar] = React.useState(false);

  const handleClose = () => {
    props.setOpen(false);

    props.path.includes("approver")
      ? navigate("/approver/requests")
      : props.path.includes("compliance")
      ? navigate("/compliance/requests")
      : navigate("/");
  };

  const handleRatingChange = (name, value) => {
    setRating((prevRating) => ({
      ...prevRating,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    setIsSubmitDisabled(true); // Disable submit button immediately
    try {
      const response = await raiseFeedback({
        user: profile?.FullName,
        uid: profile?.Uid,
        email: profile?.Email,
        RequestType: "End User",
        OverallRating: rating.overAllRating,
        FindFormRating: rating.easyToFind,
        CompleteFormRating: rating.easyToComplete,
        comments: comment,
        requestType: props.requestType,
      });
      if (response.status === 201) {
        toast.success("Feedback Submitted Successfully");
        props.path.includes("approver")
          ? navigate("/approver/requests")
          : props.path.includes("compliance")
          ? navigate("/compliance/requests")
          : navigate("/");
      }
    } catch (error) {
      toast.error(
        <div>
          {allTranslations?.failed_to_submit_feedback?.[userLang] ??
            "Failed to submit feedback"}
          <a
            href="mailto:ganeshg@aapmor.com"
            style={{ color: "blue", textDecoration: "underline" }}
          >
            {allTranslations?.contact_support?.[userLang] ?? "contact support"}
          </a>
          .
        </div>
      );
    } finally {
      setIsSubmitDisabled(false); // Enable submit button after response
    }
  };

  const handleSkip = () => {
    props.path.includes("approver")
      ? navigate("/approver/requests")
      : props.path.includes("compliance")
      ? navigate("/compliance/requests")
      : navigate("/");
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const isSubmitEnabled =
    rating.overAllRating > 0 &&
    rating.easyToFind > 0 &&
    rating.easyToComplete > 0;

  return (
    <div>
      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography variant="h6" component="div" color="text.primary">
            {allTranslations?.hey?.[userLang] ?? "Hey"},
            {allTranslations?.[
              profile?.FullName?.toLowerCase().split(" ").join("_")
            ]?.[userLang] ?? profile?.FullName}
          </Typography>
          <Typography sx={{ fontSize: 10 }} color="text.primary" gutterBottom>
            {allTranslations?.please_share_your_valuable_feedback?.[userLang] ??
              "Please share your valuable feedback"}
          </Typography>
          <Stack spacing={2} sx={{ mt: 2 }}>
            <Stack
              direction="row"
              sx={{
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography color="text.primary">
                {allTranslations?.overall_experience?.[userLang] ??
                  "Overall Experience"}
                <span style={{ color: "red" }}>*</span>
              </Typography>
              <Rating
                name="overAllRating"
                value={rating.overAllRating}
                precision={1}
                onChange={(event, newValue) =>
                  handleRatingChange("overAllRating", newValue)
                }
              />
            </Stack>
            <Stack
              direction="row"
              sx={{
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography color="text.primary">
                {allTranslations?.it_was_easy_to_find_the_request_form?.[
                  userLang
                ] ?? "It was easy to find the request form"}
                <span style={{ color: "red" }}>*</span>
              </Typography>
              <Rating
                name="easyToFind"
                value={rating.easyToFind}
                precision={1}
                onChange={(event, newValue) =>
                  handleRatingChange("easyToFind", newValue)
                }
              />
            </Stack>
            <Stack
              direction="row"
              sx={{
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography color="text.primary">
                {allTranslations?.it_was_easy_to_complete_the_request_form?.[
                  userLang
                ] ?? "It was easy to complete the request form"}
                <span style={{ color: "red" }}>*</span>
              </Typography>
              <Rating
                name="easyToComplete"
                value={rating.easyToComplete}
                precision={1}
                onChange={(event, newValue) =>
                  handleRatingChange("easyToComplete", newValue)
                }
              />
            </Stack>
            <Typography color="text.primary">
              {allTranslations?.do_you_have_any_specific_comments?.[userLang] ??
                "Do you have any specific comments"}
            </Typography>
            <OutlinedInput
              placeholder={
                allTranslations?.comment_here?.[userLang] ?? "comment here..."
              }
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            />
          </Stack>
          <Stack
            sx={{
              flexDirection: "row",
              justifyContent: "space-between",
              mt: 2,
            }}
          >
            <Button onClick={handleSkip}>
              {allTranslations?.skip?.[userLang] ?? "Skip"}
            </Button>
            <Button
              variant="contained"
              onClick={handleSubmit}
              disabled={!isSubmitEnabled || isSubmitDisabled} // Ensure button is disabled upon submit click
              sx={{
                bgcolor: "primary.main",
              }}
            >
              {allTranslations?.submit?.[userLang] ?? "Submit"}
            </Button>
          </Stack>
        </Box>
      </Modal>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={2000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          {allTranslations?.feedback_submitted?.[userLang] ??
            "Feedback submitted"}
          !
        </Alert>
      </Snackbar>
    </div>
  );
}

// import { Box, Grid, Typography } from "@mui/material";
// import React from "react";
// import { BarChart, PieChart } from "@mui/x-charts";

// const PieGraph = () => {
//   const data = [
//     { id: "Approved", value: 60, color: "#A5D6A7" }, // Green
//     { id: "Pending", value: 15, color: "#FFECB3" }, // Yellow
//     { id: "Rejected", value: 15, color: "#EF9A9A" }, // Red
//     { id: "Removed", value: 10, color: "#CE93D8" }, // Purple
//   ];

//   return (
//     <Box>
//       <Typography color={"black"} p={1} fontSize={14} fontWeight={600}>
//         Request Details
//       </Typography>
//       <Box
//         sx={{
//           bgcolor: "background.default",
//           borderRadius: 5,
//           display: "flex",
//           flexDirection: "column",
//           alignItems: "center",
//           justifyContent: "center",
//           p: 3,
//         }}
//       >
//         {/* Pie Chart */}
//         <Box
//           sx={{
//             display: "flex",
//             justifyContent: "center",
//             alignItems: "center",
//             width: "100%",
//           }}
//         >
//           <PieChart
//             sx={{
//               display: "block",
//             }}
//             width="100%"
//             height={300}
//             series={[
//               {
//                 data: data.map((item) => ({
//                   id: item.id,
//                   value: item.value,
//                   color: item.color,
//                 })),
//                 innerRadius: "40%", // For donut chart appearance
//                 outerRadius: "70%",
//                 label: (item) => `${item.value}%`, // Show percentage in slices
//               },
//             ]}
//           />
//         </Box>

//         {/* Legends */}
//         <Grid container spacing={1} justifyContent="center" sx={{ mt: 2 }}>
//           {data.map((item) => (
//             <Grid
//               item
//               xs={6}
//               key={item.id}
//               sx={{ display: "flex", alignItems: "center", gap: 1 }}
//             >
//               <Box
//                 sx={{
//                   width: 12,
//                   height: 12,
//                   bgcolor: item.color,
//                   borderRadius: "50%",
//                 }}
//               />
//               <Typography variant="body2" color={"black"}>
//                 {item.id}
//               </Typography>
//             </Grid>
//           ))}
//         </Grid>
//       </Box>
//     </Box>
//   );
// };

// export default PieGraph;
import React, { useEffect, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { getAllRequests } from "../../apiCalls/allApiCalls";

ChartJS.register(ArcElement, Tooltip, Legend);

const PieGraph = () => {
  const [requestData, setrequestData] = useState();
  const getrequestData = async () => {
    try {
      const response = await getAllRequests();

      setrequestData(response.data);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    getrequestData();
  }, []);
  const totalRequests = requestData?.length;

  const inProgressPercentage = (
    (requestData?.filter((r) => r.status === "In Progress").length /
      totalRequests) *
    100
  ).toFixed(2);
  const approvedPercentage = (
    (requestData?.filter((r) => r.status === "Approved").length /
      totalRequests) *
    100
  ).toFixed(2);
  const rejectedPercentage = (
    (requestData?.filter((r) => r.status === "Rejected").length /
      totalRequests) *
    100
  ).toFixed(2);

  console.log(
    `In Progress: ${inProgressPercentage}, Approved: ${approvedPercentage}, Rejected: ${rejectedPercentage}`
  );
  // Output: In Progress: 28.57%, Approved: 42.86%, Rejected: 28.57%

  const data = [
    { id: "Approved", value: approvedPercentage, color: "#A5D6A7" }, // Green
    { id: "Pending", value: inProgressPercentage, color: "#FFECB3" }, // Yellow
    { id: "Rejected", value: rejectedPercentage, color: "#EF9A9A" }, // Red
  ];

  const chartData = {
    labels: data.map((item) => item.id), // Extract labels
    datasets: [
      {
        data: data.map((item) => item.value), // Extract values
        backgroundColor: data.map((item) => item.color), // Extract colors
        borderWidth: 1, // Border width for the slices
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
        labels: {
          boxWidth: 15,
        },
      },
      tooltip: {
        callbacks: {
          label: (context) => `${context.label}: ${context.raw}%`,
        },
      },
    },
  };

  return (
    <Box sx={{ width: "100%", minHeight: "100%" }}>
      <Doughnut data={chartData} options={chartOptions} />
    </Box>
  );
};

export default PieGraph;

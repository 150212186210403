import React, { useState } from "react";
import {
  Drawer,
  Button,
  Typography,
  TextField,
  Box,
  IconButton,
  Stack,
  Tooltip,
} from "@mui/material";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { Close } from "@mui/icons-material";
import { useSelector } from "react-redux";

const ApproveDrawer = ({
  open,
  toggleDrawer,
  selectedRows,
  type,
  handleReject,
  handleApprove,
  requestType,
  inputValue,
  handleForwardRequest,
  requestId,
}) => {
  const { allTranslations } = useSelector((state) => state.translations);
  const { language: userLang } = useSelector((state) => state.userLanguage);
  const [resumeError] = useState("");
  const [fileNames, setFileNames] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false); // New state to track submitting
  const [comments, setComments] = useState("");

  const handleFileChange = (event) => {
    const files = event.target.files;
    const newFileNames = [...files].map((f) => f.name);
    setSelectedFiles([...files]);
    setFileNames([...fileNames, ...newFileNames]);
  };

  const filterFiles = (fName) => {
    const updatedFiles = fileNames.filter((eachFile) => eachFile !== fName);
    const updatedSelectedFiles = selectedFiles.filter(
      (eachFile) => eachFile.name !== fName
    );
    setFileNames(updatedFiles);
    setSelectedFiles(updatedSelectedFiles);
  };

  const handleSubmit = async (requestType, selectedRows) => {
    setIsSubmitting(true); // Disable submit button
    if (type === "approve") {
      await handleApprove(requestType, selectedRows);
    } else if (type === "reject") {
      await handleReject(requestType, selectedRows);
    }
    setIsSubmitting(false); // Enable button after action completes
  };

  return (
    <div>
      <Drawer
        anchor="bottom"
        open={open}
        PaperProps={{
          sx: {
            borderTopLeftRadius: 16,
            borderTopRightRadius: 16,
            px: 10,
            py: 3,
          },
        }}
      >
        <Typography variant="h6" gutterBottom textAlign={"center"}>
          {allTranslations?.[`are_you_sure_you_want_to_${type}_this_request`]?.[
            userLang
          ] ?? `Are you sure you want to ${type} this request`}?
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "16px",
            mt: 3,
          }}
        >
          <Stack direction="row" gap={1}>
            <Typography>
              {allTranslations?.request?.[userLang] + "ID" ?? "Request ID"}:
            </Typography>
            <Typography>{requestId}</Typography>
          </Stack>
          <Typography variant="body1">
            {allTranslations?.action_status?.[userLang] ?? "Action Status"} :
            {type === "approve" ? (
              <span style={{ color: "green" }}>
                <b>{allTranslations?.approve?.[userLang] ?? "Approve"}</b>
              </span>
            ) : type === "reject" ? (
              <span style={{ color: "red" }}>
                <b>{allTranslations?.reject?.[userLang] ?? "Reject"}</b>
              </span>
            ) : (
              <span style={{ color: "orange" }}>
                <b>{allTranslations?.forward?.[userLang] ?? "Forward"}</b>
              </span>
            )}
          </Typography>
        </Box>

        <Box>
          <Typography>
            {allTranslations?.comments?.[userLang] ?? "Comments"}
            <span
              style={{
                color: "tomato",
              }}
            >
              *
            </span>{" "}
            :
          </Typography>
          {/* <Box
              border={"1px solid gray"}
              width={"70%"}
              minHeight={220}
              maxHeight={260}
              sx={{ maxHeight: 180, p: 2 }}
              borderRadius={5}
            > */}
          <Stack width={"80%"} ml={4} mt={1}>
            <TextField
              variant="standard"
              InputProps={{
                disableUnderline: true,
              }}
              placeholder={
                allTranslations?.reason_for_approving_the_request?.[userLang] ??
                "Reason for approving the request"
              }
              multiline
              sx={{
                p: 2,
                width: "100%",
                marginBottom: "16px",
                minHeight: 60,
                maxHeight: 80,
                border: "1px solid gray",
                overflowY: "auto",
                "&::-webkit-scrollbar": {
                  width: "2px",
                  bgcolor: "icons.bgLight",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "icons.bgDark",
                  borderRadius: 5,
                },
                "& fieldset": { border: "none" },
              }}
              value={comments}
              onChange={(e) => setComments(e.target.value)}
            />

            <Stack direction={"row"} gap={2} alignItems={"center"}>
              <Tooltip
                title={
                  allTranslations?.attach_files?.[userLang] ?? "Attach files"
                }
              >
                <IconButton component="label" htmlFor="file-input">
                  <AttachFileIcon sx={{ color: "rgb(175, 175, 175)" }} />
                </IconButton>
              </Tooltip>
              <input
                id="file-input"
                type="file"
                multiple
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  opacity: 0,
                  cursor: "pointer",
                }}
                onChange={handleFileChange}
                hidden
              />
              <Stack
                maxHeight={80}
                direction={"row"}
                flexWrap={"wrap"}
                columnGap={2}
                width={"100%"}
                sx={{
                  overflowY: "scroll",
                  "&::-webkit-scrollbar": {
                    width: "2px",
                    bgcolor: "white",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "#13dd7c",
                    borderRadius: 5,
                  },
                }}
              >
                {fileNames &&
                  fileNames.map((fileName, i) => {
                    return (
                      <Stack
                        direction={"row"}
                        alignItems={"flex-start"}
                        key={i}
                      >
                        <Typography
                          variant="caption"
                          component={"span"}
                          display="block"
                          mt={1}
                          sx={{ color: "primary.main", lineHeight: 1.5 }}
                        >
                          {fileName}
                        </Typography>
                        <IconButton onClick={() => filterFiles(fileName)}>
                          <Close
                            sx={{
                              color: "text.primary",
                              fontSize: "18px",
                            }}
                          />
                        </IconButton>
                      </Stack>
                    );
                  })}
              </Stack>
            </Stack>
            {resumeError && (
              <Typography
                variant="caption"
                component={"span"}
                display="block"
                mt={1}
                sx={{ color: "#cd4949" }}
              >
                {allTranslations?.please_upload_file?.[userLang] ??
                  "Please upload File"}
              </Typography>
            )}
          </Stack>

          <Box sx={{ display: "flex", justifyContent: "end", gap: 3 }} mt={2}>
            <Button
              variant="contained"
              color="error"
              onClick={() => {
                if (type === "forward") {
                  handleForwardRequest(inputValue[1].trim());
                } else {
                  handleSubmit(requestType, selectedRows);
                }
              }}
              disabled={isSubmitting || !comments} // Disable button while submitting
            >
              {isSubmitting
                ? "Submitting..."
                : allTranslations?.submit?.[userLang] ?? "Submit"}
            </Button>
            <Button
              variant="outlined"
              // sx={{ color: "white", borderColor: "white" }}
              onClick={toggleDrawer(false)}
              disabled={isSubmitting} // Prevent closing while submitting
            >
              {allTranslations?.cancel?.[userLang] ?? "Cancel"}
            </Button>
          </Box>
        </Box>
      </Drawer>
    </div>
  );
};

export default ApproveDrawer;

import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import Carousel from "react-material-ui-carousel";

const Graph = ({ feedbackData }) => {
  const convertPercentagesToArray = (percentages) => {
    if (!percentages) return [];
    return [5, 4, 3, 2, 1].map((star) => percentages[star] || 0);
  };

  const datasets = [
    {
      title: feedbackData?.averages?.FindFormRating,
      rating: "1275 ratings",
      question: "It was easy to find the request form",
      percentages: convertPercentagesToArray(
        feedbackData?.percentages?.FindFormRating
      ),
      star: [5, 4, 3, 2, 1],
    },
    {
      title: feedbackData?.averages?.CompleteFormRating,
      rating: "1275 ratings",
      question: "It's easy to complete the request form",
      percentages: convertPercentagesToArray(
        feedbackData?.percentages?.CompleteFormRating
      ),
      star: [5, 4, 3, 2, 1],
    },
    {
      title: feedbackData?.averages?.OverallRating,
      rating: "1275 ratings",
      question: "Overall Experience*",
      percentages: convertPercentagesToArray(
        feedbackData?.percentages?.OverallRating
      ),
      star: [5, 4, 3, 2, 1],
    },
  ];

  // Calculate the maximum percentage to normalize bar heights
  const maxPercentage = Math.max(
    ...datasets.flatMap((dataset) => dataset.percentages)
  );

  return (
    <Box px={3} py={1}>
      <Carousel>
        {datasets.map((dataset, datasetIndex) => (
          <Box key={datasetIndex}>
            <Typography
              sx={{
                fontSize: 16,
                fontWeight: 600,
                mb: 1,
                color: "#333",
              }}
            >
              {dataset.title} Out of 5 Stars
            </Typography>
            <Typography
              sx={{
                fontSize: 14,
                mb: 3,
                color: "#333",
              }}
            >
              {feedbackData?.totalRatings} ratings
            </Typography>
            <Typography
              sx={{
                fontSize: 14,
                fontWeight: 600,
                mb: 3,
                color: "#333",
              }}
            >
              Q. {dataset.question}
            </Typography>

            {/* Dynamic Bar Chart */}
            <Stack direction="row" justifyContent="space-between" spacing={1}>
              {dataset.percentages.map((percentage, index) => (
                <Stack key={index}>
                  <Typography
                    sx={{
                      textAlign: "center",
                      fontSize: 14,
                      mb: 1,
                      fontWeight: 600,
                      color: "#ee4731",
                    }}
                  >
                    {percentage}%
                  </Typography>

                  {/* Bar */}
                  <Box
                    sx={{
                      height: "130px",
                      // height: "calc(100% - 130px)",
                      width: 40,
                      border: "2px solid #ee4731",
                      borderRadius: 2,
                      overflow: "hidden",
                      display: "flex",
                      alignItems: "flex-end",
                    }}
                  >
                    {/* Dynamic Filling */}
                    <Box
                      sx={{
                        width: "100%",
                        height: `${(percentage / maxPercentage) * 100}%`, // Normalize height
                        backgroundColor: "rgb(243, 216, 208)",
                      }}
                    />
                  </Box>

                  <Typography
                    sx={{
                      textAlign: "center",
                      fontSize: 11,
                      mb: 1,
                      color: "#ee4731",
                    }}
                  >
                    {dataset.star[index]} Star
                  </Typography>
                </Stack>
              ))}
            </Stack>
          </Box>
        ))}
      </Carousel>
    </Box>
  );
};

export default Graph;

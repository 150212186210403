import { Box, Grid, Typography } from "@mui/material";
import Graph from "./Graph";
import PieGraph from "./PieGraph";

const Staticstics = ({ feedbackData }) => {
  return (
    <Box
      sx={{
        bgcolor: "rgb(251, 251, 251)",
        borderRadius: 5,
        height: "72vh", // Ensure the parent container has a fixed height
        padding: 2,
        mt: 1,
        display: "flex", // Flex ensures children are aligned properly
        flexDirection: "column",
      }}
    >
      <Grid
        container
        spacing={1}
        sx={{
          height: "100%", // Ensure Grid uses the full height of the parent
        }}
      >
        <Grid
          item
          xs={6}
          sx={{
            height: "100%", // Each Grid item should also take full height
          }}
        >
          <Typography color={"black"} p={1} fontSize={14} fontWeight={600}>
            Feedback Reviews
          </Typography>
          <Box
            sx={{
              bgcolor: "rgb(255, 255, 255)",
              borderRadius: 5,
              height: "calc(100% - 24px)", // Adjust for Typography height
              // display: "flex", // Ensure Graph component is centered if needed
              // justifyContent: "center",
              // alignItems: "center",
            }}
          >
            <Graph feedbackData={feedbackData} />
          </Box>
        </Grid>
        <Grid
          item
          xs={6}
          sx={{
            height: "100%", // Ensure height consistency with the left Grid item
          }}
        >
          <Typography color={"black"} p={1} fontSize={14} fontWeight={600}>
            Request Details
          </Typography>
          <Box
            sx={{
              bgcolor: "rgb(255, 255, 255)",
              borderRadius: 5,
              height: "calc(100% - 24px)", // Adjust for Typography height
              display: "flex", // Ensure PieGraph component is centered if needed
              justifyContent: "center",
              alignItems: "center",
              pt: 3,
            }}
          >
            <PieGraph />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Staticstics;
